<template>
  <div>
    <div class="box">
      <div class="left">
        <i @click="ShowMenuLeft()" v-if="showMenu" class="el-icon-s-fold"></i>
        <i @click="ShowMenuLeft()" v-else class="el-icon-s-unfold"></i>
        <div class="item" v-for="(item, index) in metas" :key="index">
          <el-breadcrumb>
            <el-breadcrumb-item>
              <span v-if="index != 0" style="margin: 5px">/</span>
              {{ item.meta.title }}</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
      </div>
      <div class="right">
        <div class="nickname" style="cursor: pointer" @click="fullScreen()">
          <i class="el-icon-full-screen"></i>
        </div>
        <div
          v-if="message.report == 0 && message.auditClerk == 0"
          @click="Message()"
          class="bell"
        >
          <i class="el-icon-bell"></i>
        </div>
        <div v-else class="bell-message" @click="Message()">
          <i class="el-icon-bell"></i>
        </div>
        <div class="nickname">{{ user.nickname }}</div>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <el-image
              v-if="user.avatarUrl == null || user.avatarUrl == ''"
              :src="require('@/assets/user/user.webp')"
              :preview-src-list="[require('@/assets/user/user.webp')]"
            ></el-image>
            <el-image
              v-else
              :src="webURL + user.avatarUrl"
              :preview-src-list="[webURL + user.avatarUrl]"
            ></el-image>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="log-out">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <RightDialogVue></RightDialogVue>
  </div>
</template>

<script>
import RightDialogVue from "@/components/index/common/RightDialog.vue";
export default {
  components: { RightDialogVue },
  data() {
    return {
      showMenu: true, //是否展示右边导航栏
      meta: this.$route.meta.title, //面包屑
      user: {}, //用户信息
      webURL: this.$axios.defaults.webURL, //网站端口路径
      metas: [], //面包屑
      message: {
        report: 0, //举报条数
        auditClerk: 0, //接单员审核
      }, //消息提醒处理
    };
  },
  mounted() {
    //初始化事件
    this.init();
    //刷新路由
    this.$bus.$emit("UpdateBreadcrumb");
  },
  beforeDestroy() {},
  methods: {
    //消息通知处理
    Message() {
      this.$bus.$emit("OpenRightMessageDialog", this.message);
    },
    //进行屏幕放大事件
    fullScreen() {
      if (!document.fullscreenElement) {
        // 判断当前全屏元素，如果没有则全屏当前文档
        document.documentElement.requestFullscreen().catch((err) => {
          console.error(
            `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
          );
        });
      } else {
        // 如果已经有元素处于全屏状态，则退出全屏
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
    //初始化事件
    init() {
      //获取用户信息
      let user = localStorage.getItem("user");
      this.user = JSON.parse(user);
      this.$bus.$on("UpdateBreadcrumb", () => {
        //设置面包屑
        this.$nextTick(() => {
          this.metas = this.$route.matched;
        });
      });
      //请求消息提醒数据信息
      this.adminGetMessageDispose();
    },
    //请求消息提醒数据信息
    adminGetMessageDispose() {
      this.$axios.get("/message/adminGetMessageDispose").then((res) => {
        this.message = res.data.data;
      });
    },
    //用户选择事件
    handleCommand(command) {
      if (command == "log-out") {
        this.$router.replace("/login");
      }
    },
    //展示右边导航栏
    ShowMenuLeft() {
      this.showMenu = !this.showMenu;
      //触发事件总线
      this.$bus.$emit("ShowMenuLeft");
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  border-bottom: 1px solid #e3dfdf;
  height: 50px;
  display: flex;
  justify-content: space-between;
  .left {
    margin-left: 20px;
    display: flex;
    align-items: center;
    i {
      cursor: pointer;
      font-size: 20px;
      margin-right: 20px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .nickname {
      margin-right: 20px;
    }
    .bell {
      margin-right: 20px;
      cursor: pointer;
    }
    .bell-message {
      margin-right: 20px;
      color: red;
      cursor: pointer;
    }
    .el-image {
      cursor: pointer;
      width: 40px;
      height: 40px;
      margin-top: 5px;
      border-radius: 10px;
    }
  }
}
</style>