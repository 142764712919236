<template>
  <div>
    <el-page-header @back="goBack" content="验证页面"> </el-page-header>
    <div class="box" v-if="!showSuccess">
      <div class="title">
        <svg
          t="1714393436976"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4322"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="30"
          height="30"
        >
          <path
            d="M352.814545 385.396364m-33.512727 0a33.512727 33.512727 0 1 0 67.025455 0 33.512727 33.512727 0 1 0-67.025455 0Z"
            fill="#50B674"
            p-id="4323"
          ></path>
          <path
            d="M502.690909 384.465455m-33.512727 0a33.512727 33.512727 0 1 0 67.025454 0 33.512727 33.512727 0 1 0-67.025454 0Z"
            fill="#50B674"
            p-id="4324"
          ></path>
          <path
            d="M576.232727 534.341818m-23.272727 0a23.272727 23.272727 0 1 0 46.545455 0 23.272727 23.272727 0 1 0-46.545455 0Z"
            fill="#50B674"
            p-id="4325"
          ></path>
          <path
            d="M694.458182 536.203636m-23.272727 0a23.272727 23.272727 0 1 0 46.545454 0 23.272727 23.272727 0 1 0-46.545454 0Z"
            fill="#50B674"
            p-id="4326"
          ></path>
          <path
            d="M512 0C229.003636 0 0 229.003636 0 512s229.003636 512 512 512 512-229.003636 512-512S794.996364 0 512 0z m-87.505455 630.225455c-26.996364 0-48.407273-5.585455-75.403636-11.17091l-75.403636 37.236364 21.410909-64.232727c-53.992727-37.236364-85.643636-85.643636-85.643637-145.221818 0-102.4 96.814545-182.458182 215.04-182.458182 105.192727 0 198.283636 64.232727 216.901819 150.807273-6.516364-0.930909-13.963636-0.930909-20.48-0.93091-102.4 0-182.458182 76.334545-182.458182 170.356364 0 15.825455 2.792727 30.72 6.516363 44.683636-7.447273 0-13.963636 0.930909-20.48 0.93091z m314.647273 75.403636l15.825455 53.992727-58.647273-32.581818c-21.410909 5.585455-42.821818 11.170909-64.232727 11.170909-102.4 0-182.458182-69.818182-182.458182-155.461818s80.058182-155.461818 182.458182-155.461818c96.814545 0 182.458182 69.818182 182.458182 155.461818 0 47.476364-31.650909 90.298182-75.403637 122.88z"
            fill="#50B674"
            p-id="4327"
          ></path></svg
        >使用【最高】管理员账号扫码验证
      </div>
      <div class="img" v-if="qrcodeImage != null">
        <img :src="qrcodeImage" alt="" />
      </div>
      <div class="tip">{{ tip }}</div>
    </div>
    <div class="box" v-else>
      <el-result icon="success" title="成功提示" subTitle="身份验证成功">
        <template slot="extra">
          <el-button type="primary" size="medium" @click="goBack()"
            >返回主页</el-button
          >
        </template>
      </el-result>
    </div>
    <SocketConn></SocketConn>
  </div>
</template>

<script>
import SocketConn from "@/components/websocket/SocketConn.vue";
export default {
  components: { SocketConn },
  data() {
    return {
      qrcodeImage: null, //二维码数据
      userTempId: "", //用户id数据
      showSuccess: false, //是否扫码成功
      tip: "请在两分钟内扫码验证您的【最高】管理员身份", //提示用户的词语
      verify: null, //用户验证操作用于干嘛
      userId: "", //用户id
    };
  },
  mounted() {
    //获取操作值
    this.init();
  },
  beforeDestroy() {},
  methods: {
    //初始化数据
    init() {
      this.verify = localStorage.getItem("verify");
      //若为空则回退
      if (
        this.verify == null ||
        this.verify == undefined ||
        this.verify == ""
      ) {
        this.$router.replace("/login");
        return;
      }
      //获取当前用户id
      this.userId = localStorage.getItem("userId");
      localStorage.removeItem("userId");
      localStorage.removeItem("verify");
      //初始化数据
      this.IfSocketCloseOrSuccess();
      //生成二维码
      this.CreateQrcode();
    },
    //连接断开或登陆成功处理
    IfSocketCloseOrSuccess() {
      this.$bus.$on("ConnSocketClose", () => {
        this.tip = "二维码已过期，请重新获取二维码";
      });
      this.$bus.$on("VerifyAdmin", () => {
        //判断是否已经提示
        if (this.showSuccess) {
          return;
        }
        //判断是否验证成功
        this.showSuccess = true;
      });
    },
    //生成二维码
    CreateQrcode() {
      //生成随机数确定用户
      this.userTempId = this.generateRandomString(31);
      //开始加载
      this.showLoding = true;
      //请求数据
      this.$axios
        .get(`/qrcode/adminVerify?userTempId=${this.userTempId}`, {
          responseType: "blob",
        })
        .then((res) => {
          //把接收blob数据
          const blob = new Blob([res.data], { type: res.data.type });
          const url = URL.createObjectURL(blob);
          this.qrcodeImage = url;
          //创建连接进行监听用户的连接操作
          this.SocketConn();
        });
    },
    //进行websocket远程连接
    SocketConn() {
      this.$bus.$emit("ConnSocket", this.userTempId);
    },
    //创建随机数
    generateRandomString(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    //路由回退
    goBack() {
      if (this.$route.path != "/index/user" && this.verify == "add-admin") {
        this.$router.replace("/index/user");
        //判断是否已经扫码成功并且处理添加管理员请求
        if (this.showSuccess) {
          this.$bus.$emit("AddAdmin", this.userId);
        }
      }
      if (
        this.$route.path != "/index/manage" &&
        this.verify == "delete-admin"
      ) {
        this.$router.replace("/index/manage");
        //判断是否已经扫码成功并且处理删除管理员请求
        if (this.showSuccess) {
          this.$bus.$emit("DeleteAdmin", this.userId);
        }
      }
      if (
        this.$route.path != "/index/order" &&
        this.verify == "delete-server-admin"
      ) {
        this.$router.replace("/index/order");
        //判断是否已经扫码成功并且处理删除管理员请求
        if (this.showSuccess) {
          this.$bus.$emit("DeleteServer", this.userId);
        }
      }
    },
  },
  //路由离开
  beforeRouteLeave(form, to, next) {
    if (this.$websocket != null) {
      this.$websocket.close();
    }
    next();
  },
};
</script>

<style lang="less" scoped>
.box {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  text-align: center;
  font-size: 16px;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 20px;
    }
  }
  .img {
    width: 200px;
    height: 200px;
    margin: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tip {
    text-align: center;
    font-size: 14px;
  }
}
</style>