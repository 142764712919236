<template>
  <div>
    <div class="header">
      <div class="header-top">
        <div class="item">
          <div class="item-box" v-for="(item, index) in oneList" :key="index">
            <div class="header-top-top">
              <div class="img">
                <img :src="item.img" alt="" />
              </div>
              <div class="header-left-right">
                <div class="tip">
                  {{ item.tip }}
                </div>

                <div class="text">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="item-box" v-for="(item, index) in twoList" :key="index">
            <div class="header-top-top">
              <div class="img">
                <img :src="item.img" alt="" />
              </div>
              <div class="header-left-right">
                <div class="tip">
                  {{ item.tip }}
                </div>

                <div class="text">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="item-box" v-for="(item, index) in threeList" :key="index">
            <div class="header-top-top">
              <div class="img">
                <img :src="item.img" alt="" />
              </div>
              <div class="header-left-right">
                <div class="tip">
                  {{ item.tip }}
                </div>

                <div class="text">{{ item.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-right">
        <img src="@/assets/home/qrcode.jpg" alt="" />
      </div>
    </div>
    <div class="center">
      <!-- 2.准备具有大小的DOM容器 -->
      <div class="box" ref="box"></div>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      oneList: [
        {
          tip: "今日帖子发布",
          img: require("@/assets/home/all-server.png"),
          text: "0",
        },
        {
          tip: "今日订单发布",
          img: require("@/assets/home/all-server.png"),
          text: "0",
        },
        {
          tip: "今日用户注册",
          img: require("@/assets/home/all-server.png"),
          text: "0",
        },
      ], //帖子发布数量，订单发布数量，用户注册数量
      twoList: [
        {
          tip: "今日订单收入",
          img: require("@/assets/home/all-server.png"),
          text: "0",
        },
        {
          tip: "今日订单支出",
          img: require("@/assets/home/all-server.png"),
          text: "0",
        },
        {
          tip: "今日提现金额",
          img: require("@/assets/home/all-server.png"),
          text: "0",
        },
      ], //收入金额，支出金额，提现金额
      threeList: [
        {
          tip: "本日营业额",
          img: require("@/assets/home/all-server.png"),
          text: "0",
        },
        {
          tip: "本月营业额",
          img: require("@/assets/home/all-server.png"),
          text: "0",
        },
        {
          tip: "本年营业额",
          img: require("@/assets/home/all-server.png"),
          text: "0",
        },
      ], //日营业额，月营业额，年营业额
      echartsObj: null, //图表数据
    };
  },
  mounted() {
    //获取仪表盘数据
    this.adminPanel();
    //获取十天内数据
    this.adminGetPanelLine();
  },
  methods: {
    //获取十天内数据信息
    adminGetPanelLine() {
      this.$axios.get("/instrument/adminGetPanelLine").then((res) => {
        this.echartsObj = res.data.data;
        // 将对象转换成数组，并解析每个键为日期对象
        let entries = Object.entries(this.echartsObj).map(([key, value]) => ({
          key: new Date(key),
          value,
        }));
        // 根据日期对象对数组进行排序
        entries.sort((a, b) => a.key - b.key);
        // 创建一个新的对象，按照排序后的顺序填充数据
        this.echartsObj = entries.reduce((acc, { key, value }) => {
          acc[key.toISOString().split("T")[0]] = value; // 使用日期的字符串形式作为键
          return acc;
        }, {});
        //初始化echarts
        this.init();
      });
    },
    //获取仪表盘数据
    adminPanel() {
      this.$axios.get("/instrument/adminPanel").then((res) => {
        let data = res.data.data;
        this.oneList[0].text = data.postNum;
        this.oneList[1].text = data.serverNum;
        this.oneList[2].text = data.userSignNum;
        this.twoList[0].text = data.sumMoney;
        this.twoList[1].text = data.subMoney;
        this.twoList[2].text = data.withdrawalMoney;
        this.threeList[0].text = data.todayMoney;
        this.threeList[1].text = data.sumMoneyOfMonth;
        this.threeList[2].text = data.sumYearOfMoney;
      });
    },
    //初始化echarts数据
    init() {
      let box = this.$refs.box;
      //3.初始化实例对象 echarts.init(dom容器)
      var myChart = echarts.init(box);
      let key = Object.keys(this.echartsObj);
      let value = Object.values(this.echartsObj);
      //4.指定配置项和数据
      var option = {
        title: {
          text: "前10日订单总额图表",
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            dataView: { readOnly: false },
            magicType: { type: ["line", "bar"] },
            restore: {},
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          data: [
            key[0],
            key[1],
            key[2],
            key[3],
            key[4],
            key[5],
            key[6],
            key[7],
            key[8],
            key[9],
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [
              value[0],
              value[1],
              value[2],
              value[3],
              value[4],
              value[5],
              value[6],
              value[7],
              value[8],
              value[9],
            ],
            type: "line",
            smooth: true,
          },
        ],
      };
      //5.将配置项设置给echarts实例对象，使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 100px;
  .header-top {
    .item {
      justify-content: space-around;
      display: flex;
      align-content: center;
      .item-box {
        margin: 20px;
      }
    }
  }
  .header-top-top {
    width: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #d4d7d7;
    padding: 10px;
    img {
      width: 50px;
      height: 50px;
    }
    .header-left-right {
      .tip {
        color: #999999;
      }
    }
  }
  .header-top-bottom {
    margin-top: 20px;
    width: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #d4d7d7;
    padding: 10px;
    img {
      width: 50px;
      height: 50px;
    }
    .header-left-right {
      .tip {
        color: #999999;
      }
    }
  }
  .header-right {
  }
}
.center {
  .box {
    margin: 50px;
    width: 1400px;
    height: 500px;
  }
}
</style>