<template>
  <div>
    <el-dialog
      title="修改通知信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="box" v-if="form != null">
        <div class="form">
          <el-form :inline="true" label-width="100px">
            <el-form-item label="通知ID">
              <el-input clearable="" disabled v-model="form.id"></el-input>
            </el-form-item>

            <el-form-item label="帖子详情ID">
              <el-input
                clearable=""
                disabled
                v-model="form.detailId"
              ></el-input>
            </el-form-item>

            <el-form-item label="通知用户ID">
              <el-input clearable="" disabled v-model="form.userId"></el-input>
            </el-form-item>

            <el-form-item label="被通知用户ID">
              <el-input
                clearable=""
                disabled
                v-model="form.userMessageId"
              ></el-input>
            </el-form-item>
            <el-form-item label="通知内容">
              <el-input
                clearable=""
                type="textarea"
                v-model="form.content"
              ></el-input>
            </el-form-item>

            <el-form-item label="收藏时间">
              <el-input clearable="" v-model="form.time"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="button">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" type="warning" @click="ToLookUser"
            >查看通知用户</el-button
          >
          <el-button size="mini" type="info" @click="ToLookUserMessage"
            >查看被通知用户</el-button
          >
          <el-button size="mini" type="primary" @click="AdminUpdateUserInfo()"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      form: null, //表单信息
    };
  },
  mounted() {
    //获取用户信息
    this.GetUserInfo();
  },
  methods: {
    //查看通知用户详情
    ToLookUser() {
      localStorage.setItem("userId", this.form.userId);
      this.$router.push("/index/user/update");
      localStorage.setItem("messageId", this.form.id);
    },
    //查看被通知用户详情
    ToLookUserMessage() {
      localStorage.setItem("userId", this.form.userMessageId);
      this.$router.push("/index/user/update");
      localStorage.setItem("messageId", this.form.id);
    },
    //修改用户信息
    AdminUpdateUserInfo() {
      this.$axios.post("/message/adminUpdateMessage", this.form).then((res) => {
        this.handleClose();
        //请求数据成功
        if (res.data.code == 200) {
          this.$notify.success({ message: "修改成功" });
          this.$bus.$emit("UpdateMessage");
          return;
        }
        this.$notify.error({ message: res.data.msg });
      });
    },
    //获取用户信息
    GetUserInfo() {
      //从本地缓存拿取用户id
      let messageId = localStorage.getItem("messageId");
      //判断是否有值
      if (messageId == null || messageId == undefined || messageId == "") {
        this.handleClose();
        return;
      }
      //删除缓存值
      localStorage.removeItem("messageId");
      //获取用户信息
      this.$axios
        .get(`/message/adminGetMessageOne?id=${messageId}`)
        .then((res) => {
          this.form = res.data.data;
        });
    },
    //路由回退
    handleClose() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .form {
    .el-form {
      .el-form-item {
      }
    }
  }
  .button {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
#dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.785);
}
</style>