<template>
  <div>
    <el-dialog
      title="修改评论信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="box" v-if="form != null">
        <div class="form">
          <el-form :inline="true" label-width="100px">
            <el-form-item label="评论ID">
              <el-input clearable="" disabled v-model="form.id"></el-input>
            </el-form-item>

            <el-form-item label="评论归属帖子">
              <el-input
                clearable=""
                disabled
                v-model="form.detailId"
              ></el-input>
            </el-form-item>

            <el-form-item label="发表用户ID">
              <el-input clearable="" disabled v-model="form.userId"></el-input>
            </el-form-item>

            <el-form-item label="评论内容">
              <el-input
                type="textarea"
                clearable=""
                v-model="form.content"
              ></el-input>
            </el-form-item>

            <el-form-item label="发表时间">
              <el-input clearable="" v-model="form.time"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="button">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" type="primary" @click="AdminUpdateUserInfo()"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      form: null, //表单信息
    };
  },
  mounted() {
    //获取用户信息
    this.GetUserInfo();
  },
  methods: {
    //修改用户信息
    AdminUpdateUserInfo() {
      this.$axios.post("/comment/adminUpdateComment", this.form).then((res) => {
        this.handleClose();
        //请求数据成功
        if (res.data.code == 200) {
          this.$notify.success({ message: "修改成功" });
          this.$bus.$emit("UpdateComment");
          return;
        }
        this.$notify.error({ message: res.data.msg });
      });
    },
    //获取用户信息
    GetUserInfo() {
      //从本地缓存拿取用户id
      let commentId = localStorage.getItem("commentId");
      //判断是否有值
      if (commentId == null || commentId == undefined || commentId == "") {
        this.handleClose();
        return;
      }
      //删除缓存值
      localStorage.removeItem("commentId");
      //获取用户信息
      this.$axios
        .get(`/comment/adminGetComment?commentId=${commentId}`)
        .then((res) => {
          this.form = res.data.data;
        });
    },
    //路由回退
    handleClose() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .form {
    .el-form {
      .el-form-item {
      }
    }
  }
  .button {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
#dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.785);
}
</style>