<template>
  <div>
    <el-dialog
      title="查看举报信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="box" v-if="form != null">
        <div class="form">
          <el-form :inline="true" label-width="100px">
            <el-form-item label="举报ID">
              <el-input clearable="" disabled v-model="form.id"></el-input>
            </el-form-item>

            <el-form-item label="举报用户ID">
              <el-input clearable="" disabled v-model="form.userId"></el-input>
            </el-form-item>

            <el-form-item label="举报帖子ID">
              <el-input clearable="" disabled v-model="form.postId"></el-input>
            </el-form-item>

            <el-form-item label="举报评论ID">
              <el-input
                clearable=""
                disabled
                v-model="form.commentId"
              ></el-input>
            </el-form-item>
            <el-form-item label="举报时间">
              <el-input clearable="" disabled v-model="form.time"></el-input>
            </el-form-item>

            <el-form-item label="受理状态">
              <el-input clearable="" disabled v-model="form.status"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="button">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" type="warning" @click="ToLookUser()"
            >查看用户</el-button
          >
          <el-button
            size="mini"
            style="background-color: black; color: white"
            v-if="form.postId != null && form.postId != ''"
            @click="ToLookPost()"
            >帖子详情</el-button
          >
          <el-button
            size="mini"
            type="info"
            v-if="form.commentId != null && form.commentId != ''"
            @click="ToLookComment()"
            >评论详情</el-button
          >
          <el-button
            size="mini"
            v-if="form.status == '未受理'"
            type="primary"
            @click="AdminUpdateUserInfo()"
            >确定受理</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      form: null, //表单信息
    };
  },
  mounted() {
    //获取用户信息
    this.GetUserInfo();
  },
  methods: {
    //查看用户详情
    ToLookUser() {
      localStorage.setItem("userId", this.form.userId);
      this.$router.push("/index/user/update");
      localStorage.setItem("reportId", this.form.id);
    },
    //查看评论详情
    ToLookComment() {
      localStorage.setItem("commentId", this.form.commentId);
      this.$router.push("/index/comment/update");
      localStorage.setItem("reportId", this.form.id);
    },
    //查看帖子详情
    ToLookPost() {
      localStorage.setItem("postId", this.form.postId);
      this.$router.push("/index/post/update");
      localStorage.setItem("reportId", this.form.id);
    },
    //修改信息
    AdminUpdateUserInfo() {
      this.$prompt("请输入反馈备注：", "水院贴吧", {
        inputPlaceholder: "点击确定按钮默认反馈备注",
      })
        .then((accept) => {
          let description = accept.value;
          //如果为空直接返回
          if (description == null || description == "") {
            description = "无备注";
          }
          const adminDisposeReportAccept = {
            id: this.form.id,
            description: description,
          };
          this.$axios
            .post(`/report/adminUpdateReportStatus`, adminDisposeReportAccept)
            .then((res) => {
              this.handleClose();
              //请求数据成功
              if (res.data.code == 200) {
                this.$notify.success({ message: "受理成功" });
                this.$bus.$emit("UpdateReport");
                return;
              }
              this.$notify.error({ message: res.data.msg });
            });
        })
        .catch(() => {});
    },
    //获取用户信息
    GetUserInfo() {
      //从本地缓存拿取用户id
      let reportId = localStorage.getItem("reportId");
      //判断是否有值
      if (reportId == null || reportId == undefined || reportId == "") {
        this.handleClose();
        return;
      }
      //删除缓存值
      localStorage.removeItem("reportId");
      //获取用户信息
      this.$axios
        .get(`/report/adminGetReportOne?id=${reportId}`)
        .then((res) => {
          this.form = res.data.data;
        });
    },
    //路由回退
    handleClose() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .form {
    .el-form {
      .el-form-item {
      }
    }
  }
  .button {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
#dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.785);
}
</style>