<template>
  <div>
    <el-dialog
      title="查看作品信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="box" v-if="form != null">
        <div class="form">
          <el-form :inline="true" label-width="100px">
            <el-form-item label="作品ID">
              <el-input
                clearable=""
                type="textarea"
                disabled
                v-model="form.id"
              ></el-input>
            </el-form-item>
            <el-form-item label="真实姓名">
              <el-input
                clearable
                type="textarea"
                disabled
                v-model="form.username"
              ></el-input>
            </el-form-item>
            <el-form-item label="学院">
              <el-input
                clearable=""
                type="textarea"
                disabled
                v-model="form.college"
              ></el-input>
            </el-form-item>
            <el-form-item label="专业">
              <el-input
                clearable=""
                type="textarea"
                disabled
                v-model="form.major"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input
                clearable=""
                type="textarea"
                disabled
                v-model="form.email"
              ></el-input>
            </el-form-item>
            <el-form-item label="作品名称">
              <el-input
                clearable=""
                type="textarea"
                disabled
                v-model="form.productionName"
              ></el-input>
            </el-form-item>
            <el-form-item label="作品语言">
              <el-input
                clearable=""
                disabled
                type="textarea"
                v-model="form.language"
              ></el-input>
            </el-form-item>
            <el-form-item label="作品环境">
              <el-input
                clearable=""
                disabled
                type="textarea"
                v-model="form.environment"
              ></el-input>
            </el-form-item>
            <el-form-item label="团队成员">
              <el-input
                clearable=""
                disabled
                type="textarea"
                v-model="form.team"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否发表">
              <el-input
                clearable=""
                disabled
                type="textarea"
                v-model="form.publish"
              ></el-input>
            </el-form-item>
            <el-form-item label="作品介绍">
              <el-input
                clearable=""
                disabled
                type="textarea"
                v-model="form.introduce"
              ></el-input>
            </el-form-item>

            <el-form-item label="使用说明">
              <el-input
                clearable=""
                type="textarea"
                disabled
                v-model="form.productionExplain"
              ></el-input>
            </el-form-item>

            <el-form-item label="版本信息">
              <el-input
                clearable=""
                disabled
                type="textarea"
                v-model="form.productionVersion"
              ></el-input>
            </el-form-item>

            <el-form-item label="提交时间">
              <el-input
                clearable=""
                type="textarea"
                disabled
                v-model="form.productionTime"
              ></el-input>
            </el-form-item>

            <el-form-item label="用户ID">
              <el-input
                clearable=""
                type="textarea"
                disabled
                v-model="form.userId"
              ></el-input>
            </el-form-item>

            <el-form-item label="用户电话">
              <el-input
                clearable=""
                type="textarea"
                disabled
                v-model="form.productionPhone"
              ></el-input>
            </el-form-item>

            <el-form-item label="审核状态">
              <el-input
                clearable=""
                type="textarea"
                disabled
                v-model="form.productionStatus"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="button">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" type="warning" @click="ToLookUser()"
            >查看用户</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      form: null, //表单信息
    };
  },
  mounted() {
    //获取用户信息
    this.GetUserInfo();
  },
  methods: {
    //查看用户详情
    ToLookUser() {
      localStorage.setItem("userId", this.form.userId);
      this.$router.push("/index/user/update");
      localStorage.setItem("reportId", this.form.id);
    },
    //修改信息
    AdminUpdateUserInfo() {
      this.$prompt("请输入反馈备注：", "水院贴吧", {
        inputPlaceholder: "点击确定按钮默认反馈备注",
      })
        .then((accept) => {
          let description = accept.value;
          //如果为空直接返回
          if (description == null || description == "") {
            description = "无备注";
          }
          const adminDisposeReportAccept = {
            id: this.form.id,
            description: description,
          };
          this.$axios
            .post(`/report/adminUpdateReportStatus`, adminDisposeReportAccept)
            .then((res) => {
              this.handleClose();
              //请求数据成功
              if (res.data.code == 200) {
                this.$notify.success({ message: "受理成功" });
                this.$bus.$emit("UpdateReport");
                return;
              }
              this.$notify.error({ message: res.data.msg });
            });
        })
        .catch(() => {});
    },
    //获取用户信息
    GetUserInfo() {
      //从本地缓存拿取用户id
      let productionId = localStorage.getItem("productionId");
      //判断是否有值
      if (
        productionId == null ||
        productionId == undefined ||
        productionId == ""
      ) {
        this.handleClose();
        return;
      }
      //删除缓存值
      localStorage.removeItem("productionId");
      //获取用户信息
      this.$axios
        .get(`/production/adminGetUserProductionOne?id=${productionId}`)
        .then((res) => {
          console.log(res);
          this.form = res.data.data;
        });
    },
    //路由回退
    handleClose() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .form {
    .el-form {
      .el-form-item {
      }
    }
  }
  .button {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
#dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.785);
}
</style>