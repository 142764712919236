import Vue from "vue";
import Vuex from "vuex";

//使用
Vue.use(Vuex);

const store = new Vuex.Store({
  //全局数据
  state: {
    qrcode: null, //二维码数据存放
  },
  //方法
  mutations: {
    //设置二维码数据
    SetQrcode(state, qrcode) {
      state.qrcode = qrcode;
    },
  },
  //获取store的值
  getters: {
    //获取二维码数据
    GetQrcode(state) {
      return state.qrcode;
    },
  },
  //异步执行
  actions: {},
});

export default store;
