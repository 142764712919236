<template>
  <div>
    <div class="header">
      <div class="top">
        <div class="left">
          <i class="el-icon-search"></i>
          <span> 筛选搜索</span>
        </div>
        <div class="right">
          <el-button size="mini" icon="el-icon-refresh" @click="Refresh()"
            >重置搜索</el-button
          >
          <el-button
            size="mini"
            icon="el-icon-search"
            type="primary"
            @click="Search()"
            >查询结果</el-button
          >
        </div>
      </div>
      <div class="bottom">
        <el-form inline>
          <el-row>
            <el-col :span="8">
              <el-form-item label="收藏ID：">
                <el-input clearable v-model="form.id"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收藏帖子ID：">
                <el-input clearable v-model="form.postId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收藏用户ID：">
                <el-input clearable v-model="form.userId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="收藏时间：">
                <el-date-picker
                  clearable
                  v-model="signTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始范围"
                  end-placeholder="结束范围"
                  align="right"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="list">
      <div class="top">
        <div class="left">
          <i class="el-icon-tickets"></i>
          <span> 数据列表</span>
        </div>
      </div>
    </div>
    <div class="center">
      <el-table
        :data="collects"
        border=""
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="排序">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="收藏ID" prop="postId"></el-table-column>
        <el-table-column label="收藏时间" prop="time"></el-table-column>
        <el-table-column label="操作" prop="">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="UpdateInfo(collects[scope.$index].id)"
              >编辑</el-button
            >
            <el-button size="mini" @click="Delete(collects[scope.$index].id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <div class="batch">
        <div class="left">
          <el-select placeholder="批量操作" clearable="" v-model="batch">
            <el-option value="批量删除"></el-option>
          </el-select>
        </div>
        <div class="right">
          <el-button type="primary" @click="BatchUpdate()">确定</el-button>
        </div>
      </div>
      <div class="page">
        <el-pagination
          @current-change="onCurrentChange"
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        id: "", //收藏id
        postId: "", //收藏的帖子id
        userId: "", //收藏的用户id
        skip: 1, //翻页数据
        startTime: "", //
        endTime: "", //结束时间
      }, //搜索表单数据
      collects: [], //帖子数据列表
      total: 0, //条数信息
      signTime: null, //注册时间
      batch: "", //用户选择操作
      deletes: [], //批量删除操作
    };
  },
  mounted() {
    //查询数据
    this.GetAll();
    //初始化数据
    this.init();
  },
  beforeDestroy() {},
  methods: {
    //初始化数据
    init() {
      //重新请求数据
      this.$bus.$on("UpdateCollect", () => {
        this.GetAll();
      });
    },
    //展示弹窗信息
    UpdateInfo(id) {
      localStorage.setItem("collectId", id);
      this.$router.push("/index/collect/update");
    },
    //批量操作
    BatchUpdate() {
      //批量操作为空
      if (this.batch == "") {
        this.$notify.warning({ message: "请先选择操作" });
        return;
      }
      //判断是否为空
      if(this.deletes.length==0){
        this.$notify.warning({message:"数据为空"})
        return
      }
      //获取数据
      let deletes = [];
      this.deletes.forEach((item) => {
        deletes.push(item.id);
      });
      //批量删除操作
      if (this.batch == "批量删除") {
        //询问确定删除
        this.$confirm("确定批量删除用户收藏吗？", "水院论坛", {
          type: "warning",
        })
          .then(() => {
            this.$axios
              .post("/collect/adminDeleteCollects", deletes)
              .then((res) => {
                //判断是否删除成功
                if (res.data.code == 200) {
                  this.$notify.success({ message: "删除成功" });
                  this.GetAll();
                  return;
                }
                this.$notify.error({ message: res.data.msg });
              });
          })
          .catch(() => {});
      }
    },
    //删除操作
    Delete(id) {
      //询问确定删除
      this.$confirm("确定删除该用户收藏吗？", "水院论坛", {
        type: "warning",
      })
        .then(() => {
          //发起删除请求
          this.$axios
            .get(`/collect/adminDeleteCollect?id=${id}`)
            .then((res) => {
              //判断是否删除成功
              if (res.data.code == 200) {
                this.$notify.success({ message: "删除成功" });
                this.GetAll();
                return;
              }
              this.$notify.error({ message: res.data.msg });
            });
        })
        .catch(() => {});
    },
    //翻页操作
    onCurrentChange(e) {
      this.form.skip = e;
      this.GetAll();
    },
    //重置搜索
    Refresh() {
      //刷新form表单
      let key = Object.keys(this.form);
      key.forEach((item) => {
        this.form[item] = "";
      });
      this.form.skip = 1;
      this.signTime = "";
      //请求数据
      this.GetAll();
    },
    //选择数据
    handleSelectionChange(e) {
      this.deletes = e;
    },
    //获取信息
    Search() {
      //查询时间数据
      if (this.signTime != null) {
        this.form.startTime = this.signTime[0];
        this.form.endTime = this.signTime[1];
      }
      this.GetAll();
    },
    //获取用户信息
    GetAll() {
      this.$axios.post("/collect/adminGetCollects", this.form).then((res) => {
        this.total = res.data.code;
        this.collects = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 90%;
  border: 1px solid #e3dfdf86;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .top {
    display: flex;
    justify-content: space-between;
  }
  .bottom {
    margin-top: 30px;
  }
}

.list {
  width: 90%;
  border: 1px solid #e3dfdf86;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .top {
    display: flex;
    justify-content: space-between;
  }
}
.center {
  width: 90%;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .el-table {
    margin: auto;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  .batch {
    display: flex;
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>