<template>
  <div></div>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      baseURL: this.$axios.defaults.baseURL.slice(7), //获取最终路径
    };
  },
  mounted() {
    this.$bus.$on("ConnSocket", (userTempId) => {
      this.WebSocketWeb(userTempId);
    });
  },
  beforeDestroy() {
    this.$bus.$off();
  },
  methods: {
    //建立websocket连接
    WebSocketWeb(userTempId) {
      let websocket = null;
      if ("WebSocket" in window) {
        websocket = new WebSocket(`wss://${this.baseURL}/ws/${userTempId}`);
      } else {
        alert("请更换新版浏览器！");
      }
      websocket.onopen = () => {
        Vue.prototype.$websocket = websocket;
      };
      websocket.onmessage = (e) => {
        //解析数据
        let data = JSON.parse(e.data);
        //判断是否为210管理员身份验证
        if (data.code == 210) {
          this.$bus.$emit("VerifyAdmin");
          return;
        }
        //判断是否登录
        if (data.code == 200) {
          //登录成功
          this.$bus.$emit("LoginSuccess", data);
          return;
        }
      };
      websocket.onclose = () => {
        //触发告诉用户二维码过期
        this.$bus.$emit("ConnSocketClose");
      };
      websocket.onerror = (error) => {
        console.log("连接错误", error);
      };
    },
  },
};
</script>

<style>
</style>