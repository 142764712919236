<template>
  <div>
    <el-dialog
      title="修改收藏信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="box" v-if="form != null">
        <div class="form">
          <el-form :inline="true" label-width="100px">
            <el-form-item label="收藏ID">
              <el-input clearable="" disabled v-model="form.id"></el-input>
            </el-form-item>

            <el-form-item label="收藏帖子ID">
              <el-input clearable="" disabled v-model="form.postId"></el-input>
            </el-form-item>

            <el-form-item label="收藏用户ID">
              <el-input clearable="" disabled v-model="form.userId"></el-input>
            </el-form-item>

            <el-form-item label="收藏时间">
              <el-input clearable="" v-model="form.time"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="button">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" type="warning" @click="ToLookCollectUser"
            >查看收藏用户</el-button
          >
          <el-button size="mini" type="info" @click="ToLookCollectPost"
            >查看收藏帖子</el-button
          >
          <el-button size="mini" type="primary" @click="AdminUpdateUserInfo()"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      form: null, //表单信息
    };
  },
  mounted() {
    //获取用户信息
    this.GetUserInfo();
  },
  methods: {
    //查看收藏帖子详情
    ToLookCollectPost() {
      localStorage.setItem("postId", this.form.postId);
      this.$router.push("/index/post/update");
      localStorage.setItem("collectId", this.form.id);
    },
    //查看收藏用户详情
    ToLookCollectUser() {
      localStorage.setItem("userId", this.form.userId);
      this.$router.push("/index/user/update");
      localStorage.setItem("collectId", this.form.id);
    },
    //修改用户信息
    AdminUpdateUserInfo() {
      this.$axios.post("/collect/adminUpdateCollect", this.form).then((res) => {
        this.handleClose();
        //请求数据成功
        if (res.data.code == 200) {
          this.$notify.success({ message: "修改成功" });
          this.$bus.$emit("UpdateCollect");
          return;
        }
        this.$notify.error({ message: res.data.msg });
      });
    },
    //获取用户信息
    GetUserInfo() {
      //从本地缓存拿取用户id
      let collectId = localStorage.getItem("collectId");
      //判断是否有值
      if (collectId == null || collectId == undefined || collectId == "") {
        this.handleClose();
        return;
      }
      //删除缓存值
      localStorage.removeItem("collectId");
      //获取用户信息
      this.$axios
        .get(`/collect/adminGetCollect?collectId=${collectId}`)
        .then((res) => {
          this.form = res.data.data;
        });
    },
    //路由回退
    handleClose() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .form {
    .el-form {
      .el-form-item {
      }
    }
  }
  .button {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
#dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.785);
}
</style>