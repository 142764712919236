<template>
  <div>
    <el-dialog
      title="修改接单员信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="box" v-if="form != null">
        <div class="form">
          <el-form :inline="true" label-width="100px">
            <el-form-item label="接单员ID">
              <el-input clearable="" disabled v-model="form.id"></el-input>
            </el-form-item>

            <el-form-item label="用户ID">
              <el-input
                clearable=""
                disabled
                v-model="form.clerkUserId"
              ></el-input>
            </el-form-item>

            <el-form-item label="接单员状态">
              <el-select v-model="form.clerkStatus" clearable>
                <el-option value="正常"></el-option>
                <el-option value="封禁"></el-option>
                <el-option
                  v-if="clerkStatus == '审核'"
                  value="审核"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="接单次数">
              <el-input
                clearable=""
                type="number"
                v-model="form.orderNum"
              ></el-input>
            </el-form-item>

            <el-form-item label="接单员电话">
              <el-input
                clearable=""
                type="number"
                v-model="form.phone"
              ></el-input>
            </el-form-item>

            <el-form-item label="注册时间">
              <el-input clearable="" v-model="form.clerkTime"></el-input>
            </el-form-item>

            <el-form-item label-width="800" label="接单员认证学生信息照片">
              <el-image
                style="width: 200px; height: 200px"
                :src="webURL + form.clerkStu"
                :preview-src-list="[webURL + form.clerkStu]"
              >
              </el-image>
            </el-form-item>
          </el-form>
        </div>
        <div class="button">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" type="warning" @click="ToLookUser()"
            >用户信息</el-button
          >
          <el-button
            size="mini"
            type="danger"
            v-if="clerkStatus == '审核'"
            @click="adminPassClerkAudit()"
            >受理审核状态</el-button
          >
          <el-button size="mini" type="primary" @click="AdminUpdateUserInfo()"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true, //展示弹窗
      form: null, //表单信息
      webURL: this.$axios.defaults.webURL, //网站端口路径
      clerkStatus: "", //是否展示审核状态
    };
  },
  mounted() {
    //获取用户信息
    this.GetUserInfo();
  },
  methods: {
    //查看用户信息
    ToLookUser() {
      localStorage.setItem("userId", this.form.clerkUserId);
      this.$router.push("/index/user/update");
      localStorage.setItem("clerkId", this.form.id);
    },
    //修改用户信息
    AdminUpdateUserInfo() {
      //判断是否在审核状态
      if (this.clerkStatus == "审核") {
        this.$notify.warning({ message: "当前正在审核状态，请先进行审核！" });
        return;
      }
      //发起请求
      this.$axios.post("/user/adminUpdateClerk", this.form).then((res) => {
        this.handleClose();
        //请求数据成功
        if (res.data.code == 200) {
          this.$notify.success({ message: "修改成功" });
          this.$bus.$emit("UpdateClerk");
          return;
        }
        this.$notify.error({ message: res.data.msg });
      });
    },
    //通过审核数据
    adminPassClerkAudit() {
      //判断审核状态
      if (this.form.clerkStatus == "审核") {
        this.$notify.warning({
          message:
            "请选择正常或者封禁，若选择封禁则为不通过该用户认证接单员审核！",
        });
        return;
      }
      //获取备注
      this.$prompt(
        `请输入审核备注，当前审核状态：${this.form.clerkStatus}`,
        "水院贴吧",
        {
          inputPlaceholder: "点击确定按钮默认审核备注",
        }
      )
        .then((accept) => {
          let description = accept.value;
          //如果为空直接返回
          if (description == null || description == "") {
            description = "联系在线客服查询";
            if (this.form.clerkStatus == "正常") {
              description = "审核通过，尽快接单吧！";
            }
          }
          //判断备注是否过长
          if (description.length > 15) {
            this.$notify.error({ message: "备注过长" });
            return;
          }
          //创建对象
          const adminPassClerkAccept = {
            id: this.form.id,
            userId: this.form.clerkUserId,
            status: this.form.clerkStatus,
            description: description,
          };
          //发起请求数据
          this.$axios
            .post(`/user/adminPassClerkAudit`, adminPassClerkAccept)
            .then((res) => {
              if (res.data.code == 200) {
                this.$notify.success({ message: "修改成功" });
                this.$bus.$emit("UpdateClerk");
                this.handleClose();
                return;
              }
              this.$notify.error({ message: res.data.msg });
              this.handleClose();
            });
        })
        .catch(() => {});
    },
    //获取用户信息
    GetUserInfo() {
      //从本地缓存拿取用户id
      let clerkId = localStorage.getItem("clerkId");
      //判断是否有值
      if (clerkId == null || clerkId == undefined || clerkId == "") {
        this.handleClose();
        return;
      }
      //删除缓存值
      localStorage.removeItem("clerkId");
      //获取用户信息
      this.$axios.get(`/user/adminGetClerkOne?id=${clerkId}`).then((res) => {
        this.form = res.data.data;
        this.clerkStatus = this.form.clerkStatus;
      });
    },
    //路由回退
    handleClose() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .form {
    .el-form {
      .el-form-item {
      }
    }
  }
  .button {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
#dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.785);
}
</style>