<template>
  <div>
    <div class="header">
      <div class="top">
        <div class="left">
          <i class="el-icon-search"></i>
          <span> 筛选搜索</span>
        </div>
        <div class="right">
          <el-button size="mini" icon="el-icon-refresh" @click="Refresh()"
            >重置搜索</el-button
          >
          <el-button
            size="mini"
            icon="el-icon-search"
            type="primary"
            @click="Search()"
            >查询结果</el-button
          >
        </div>
      </div>
      <div class="bottom">
        <el-form inline>
          <el-row>
            <el-col :span="8">
              <el-form-item label="用户ID：">
                <el-input clearable v-model="form.id"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="昵称：">
                <el-input clearable v-model="form.nickname"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电话：">
                <el-input clearable v-model="form.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="注册时间：">
                <el-date-picker
                  v-model="signTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始范围"
                  end-placeholder="结束范围"
                  align="right"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="list">
      <div class="top">
        <div class="left">
          <i class="el-icon-tickets"></i>
          <span> 数据列表</span>
        </div>
      </div>
    </div>
    <div class="center">
      <el-table
        :data="users"
        border=""
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="排序">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="昵称" prop="nickname"></el-table-column>
        <el-table-column label="注册时间" prop="signTime"></el-table-column>
        <el-table-column label="操作" prop="">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="UpdateUserInfo(users[scope.$index].id)"
              >编辑信息</el-button
            >
            <el-button size="mini" @click="Verify(users[scope.$index].id)"
              >添加管理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <div class="batch">
        <div class="left">
          <el-select placeholder="批量操作" clearable="" v-model="batch">
          </el-select>
        </div>
        <div class="right">
          <el-button type="primary" @click="BatchUpdate()">确定</el-button>
        </div>
      </div>
      <div class="page">
        <el-pagination
          @current-change="onCurrentChange"
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        id: "", //用户id
        nickname: "", //用户名称
        phone: "", //用户电话
        skip: 1, //搜索翻页
        startTime: "", //开始时间
        endTime: "", //结束时间
      }, //搜索表单数据
      users: [], //用户数据列表
      total: 0, //条数信息
      signTime: null, //注册时间
      batch: "", //用户选择操作
    };
  },
  mounted() {
    //查询数据
    this.GetAllUserInfo();
    //初始化数据
    this.init();
  },
  beforeDestroy() {},
  methods: {
    //展示弹窗信息
    UpdateUserInfo(id) {
      localStorage.setItem("userId", id);
      this.$router.push("/index/user/update");
    },
    //初始化数据
    init() {
      //绑定用户添加管理员
      this.$bus.$on("AddAdmin", (userId) => {
        this.AddAdmin(userId);
      });
      //重新请求数据
      this.$bus.$on("AdminUpdateUserInfo", () => {
        this.GetAllUserInfo();
      });
    },
    //添加管理员操作
    AddAdmin(userId) {
      //判断userId是否为空
      if (userId == null || userId == "") {
        return;
      }
      //请求添加管理员
      this.$axios.get(`/user/adminAddAdmin?userId=${userId}`).then((res) => {
        if (res.data.code == 200) {
          this.$notify.success({ message: "添加成功" });
          return;
        }
        this.$notify.error({ message: res.data.msg });
      });
    },
    //批量操作
    BatchUpdate() {},
    //删除用户
    DeleteUser() {
      //
    },
    //添加管理员
    Verify(id) {
      localStorage.setItem("verify", "add-admin");
      localStorage.setItem("userId", id);
      //验证身份
      this.$router.push("/verify");
    },
    //翻页操作
    onCurrentChange(e) {
      this.form.skip = e;
      this.GetAllUserInfo();
    },
    //重置搜索
    Refresh() {
      //刷新form表单
      let key = Object.keys(this.form);
      key.forEach((item) => {
        this.form[item] = "";
      });
      this.form.skip = 1;
      this.signTime = "";
      //请求数据
      this.GetAllUserInfo();
    },
    //选择数据
    handleSelectionChange() {},
    //获取信息
    Search() {
      if (this.signTime != null) {
        this.form.startTime = this.signTime[0];
        this.form.endTime = this.signTime[1];
      }
      this.GetAllUserInfo();
    },
    //获取用户信息
    GetAllUserInfo() {
      this.$axios.post("/user/adminGetAllUserInfo", this.form).then((res) => {
        this.users = res.data.data.list;
        this.total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 90%;
  border: 1px solid #e3dfdf86;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .top {
    display: flex;
    justify-content: space-between;
  }
  .bottom {
    margin-top: 30px;
  }
}

.list {
  width: 90%;
  border: 1px solid #e3dfdf86;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .top {
    display: flex;
    justify-content: space-between;
  }
}
.center {
  width: 90%;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .el-table {
    margin: auto;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  .batch {
    display: flex;
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>