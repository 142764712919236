<template>
  <div>
    <el-dialog
      title="编辑订单详情"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="box" v-if="form != null">
        <div class="form">
          <el-form :inline="true">
            <el-form-item label="订单关联ID">
              <el-input clearable="" disabled v-model="form.id"></el-input>
            </el-form-item>

            <el-form-item label="性别">
              <el-select v-model="form.serverSex" clearable="">
                <el-option value="男生"></el-option>
                <el-option value="女生"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="游戏开黑">
              <el-input
                clearable=""
                type="textarea"
                v-model="form.serverGameMessage"
              ></el-input>
            </el-form-item>

            <el-form-item label="备注信息">
              <el-input
                type="textarea"
                clearable=""
                v-model="form.serverDescription"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="button">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" type="primary" @click="AdminUpdateUserInfo()"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      form: null, //表单信息
    };
  },
  mounted() {
    //获取用户信息
    this.GetUserInfo();
  },
  methods: {
    //修改用户信息
    AdminUpdateUserInfo() {
      this.$axios.post("/server/adminUpdateGameOne", this.form).then((res) => {
        this.handleClose();
        //请求数据成功
        if (res.data.code == 200) {
          this.$notify.success({ message: "修改成功" });
          this.$bus.$emit("UpdateReview");
          return;
        }
        this.$notify.error({ message: res.data.msg });
      });
    },
    //获取用户信息
    GetUserInfo() {
      //从本地缓存拿取用户id
      let orderId = localStorage.getItem("orderId");
      //判断是否有值
      if (orderId == null || orderId == undefined || orderId == "") {
        this.handleClose();
        return;
      }
      //删除缓存值
      localStorage.removeItem("orderId");
      //获取用户信息
      this.$axios
        .get(`/server/adminGetServerOrderOne?id=${orderId}&serverType=游戏开黑`)
        .then((res) => {
          this.form = res.data.data;
        });
    },
    //路由回退
    handleClose() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .form {
    .el-form {
      .el-form-item {
      }
    }
  }
  .button {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
#dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.785);
}
</style>