//引入axios封装
import axios from "axios";
//引入加载动画样式
import NProgress from "nprogress";
//引入加载动画样式的css库
import "nprogress/nprogress.css";
//引入路由
import router from "@/router";
//引入ui库
import { MessageBox } from "element-ui";
//引入token依赖库
import { Decrypt } from "@/utils/secret.js";

//创建一个axios实例
const server = axios.create({
  // history: true,
  baseURL: "https://www.sytd.asia:8097", //服务器后端
  webURL: "https://www.sytd.asia/static", //服务器前端
  // webURL: "http://192.168.1.210:8096", //服务器前端
  // baseURL: "http://localhost:8097", //本地后端
  // baseURL: "http://192.168.1.237:8097", //本地后端
  // webURL: "http://192.168.1.210:8097",//本地前端
  // baseURL: "http://192.168.1.235:8097", //本地服务器后端
  // withCredentials: true,
  timeout: 10000,
});

//请求拦截器
server.interceptors.request.use((config) => {
  // //请求发起的时候设置加载状态
  NProgress.start();
  //如果长时间未响应则结束加载状态
  setTimeout(() => {
    NProgress.done();
  }, 10000);
  //获取请求头
  let token = localStorage.getItem("token");
  //若不为null则带上请求头
  if (token != null) {
    token = Decrypt(Decrypt(token));
    config.headers.Authorization = token;
  }
  return config;
});

//响应拦截器
server.interceptors.response.use(
  (res) => {
    // 请求结束关闭加载;
    NProgress.done();
    //判断是否被拦截
    if (res.data == 410) {
      localStorage.clear();
      //跳转主页
      if (router.currentRoute.path != "/login") {
        // 使用 confirm 方法
        MessageBox.confirm("登录认证过期，是否重新登录?", "水院论坛", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            router.replace("/login");
          })
          .catch(() => {});
        return res;
      }
    }
    return res;
  },
  (error) => {
    console.error("Axios Response Error:", error);
    return Promise.reject(error);
  }
);

//暴漏实例
export default server;
