import router from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

import PageLogin from "@/pages/login/PageLogin.vue";
import PageQrcode from "@/pages/login/PageQrcode.vue";

let routerView = new router({
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      component: PageLogin,
    },
    {
      path: "/qrcode",
      component: PageQrcode,
    },
  ],
});

//跳转前增加进度条
routerView.beforeEach(async (to, from, next) => {
  NProgress.start();
  next();
});
//跳转后取消进度条
routerView.afterEach(() => {
  NProgress.done();
});

export default routerView;
