<template>
  <div style="display: flex; justify-content: center; margin: auto">
    <div class="address-box">
      <div class="header">
        <div class="left">
          <div class="chooseSwitch" v-if="item.chooseSwitch == 0">
            <el-tag type="info">默认</el-tag>
          </div>
          <div class="dorm">
            <el-tag v-if="item.dorm == '男生宿舍'">男生宿舍</el-tag>
            <el-tag v-else-if="item.dorm == '女生宿舍'" type="success"
              >女生宿舍</el-tag
            >
            <el-tag v-else type="warning">其它区域</el-tag>
          </div>
        </div>
        <div class="right">
          {{ item.time }}
        </div>
      </div>
      <div class="center">{{ item.house }}-{{ item.houseNumber }}</div>
      <div class="footer">
        <div class="left">
          <div class="username">{{ item.username }}</div>
          <div class="phone">{{ item.phone }}</div>
        </div>
        <div class="right"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.address-box {
  color: #999999;
  width: 400px;
  margin: 30px auto;
  border: 1px solid #f4f4f4;
  box-shadow: 5px 5px 10px #f4f4f4, -5px 5px 10px #f4f4f4, 5px -5px 10px #f4f4f4,
    -5px -5px 10px #f4f4f4;
  padding: 20px 0px;
  overflow: auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;

      .chooseSwitch {
        margin-left: 20px;
      }

      .dorm {
        margin-left: 20px;
      }
    }

    .right {
      margin-right: 20px;
      font-size: 16px;
    }
  }

  .center {
    margin: 20px;
    font-size: 16px;
  }

  .footer {
    display: flex;
    font-size: 16px;
    margin-left: 20px;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;

      .phone {
        margin-left: 20px;
      }
    }

    .right {
      margin-right: 20px;
    }
  }
}
</style>