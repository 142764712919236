<template>
  <div>
    <div class="header">
      <div class="top">
        <div class="left">
          <i class="el-icon-search"></i>
          <span> 筛选搜索</span>
        </div>
        <div class="right">
          <el-button size="mini" icon="el-icon-refresh" @click="Refresh()"
            >重置搜索</el-button
          >
          <el-button
            size="mini"
            icon="el-icon-search"
            type="primary"
            @click="Search()"
            >查询结果</el-button
          >
        </div>
      </div>
      <div class="bottom">
        <el-form inline>
          <el-row>
            <el-col :span="8">
              <el-form-item label="用户ID：">
                <el-input clearable v-model="userId"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="list">
      <div class="top">
        <div class="left">
          <i class="el-icon-tickets"></i>
          <span> 数据列表</span>
        </div>
      </div>
    </div>
    <div class="center">
      <el-table
        :data="addresses"
        border=""
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="排序">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="用户昵称" prop="userId"></el-table-column>
        <el-table-column label="操作" prop="">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="UpdateInfo(addresses[scope.$index].id)"
              >编辑</el-button
            >
            <el-button size="mini" @click="Delete(addresses[scope.$index].id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <div class="batch">
        <div class="left">
          <el-select placeholder="批量操作" clearable="" v-model="batch">
          </el-select>
        </div>
        <div class="right">
          <el-button type="primary" @click="BatchUpdate()">确定</el-button>
        </div>
      </div>
      <div class="page">
        <el-pagination
          @current-change="onCurrentChange"
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      skip: 1, //分页数据
      userId: "", //用户id
      addresses: [], //帖子数据列表
      total: 0, //条数信息
      signTime: null, //注册时间
      batch: "", //用户选择操作
      deletes: [], //批量删除操作
    };
  },
  mounted() {
    //查询数据
    this.GetAll();
    //初始化数据
    this.init();
  },
  beforeDestroy() {},
  methods: {
    //初始化数据
    init() {
      //重新请求数据
      this.$bus.$on("UpdateReview", () => {
        this.GetAll();
      });
    },
    //展示弹窗信息
    UpdateInfo(id) {
      localStorage.setItem("addressId", id);
      this.$router.push("/index/address/update");
    },
    //批量操作
    BatchUpdate() {},
    //删除操作
    Delete(id) {
      //询问确定删除
      this.$confirm("确定删除编号:" + id + "的地址吗？", "水院论坛", {
        type: "warning",
      })
        .then(() => {
          //发起删除请求
          this.$axios
            .get(`/address/adminDeleteAddress?id=${id}`)
            .then((res) => {
              //判断是否删除成功
              if (res.data.code == 200) {
                this.$notify.success({ message: "删除成功" });
                this.GetAll();
                return;
              }
              this.$notify.error({ message: res.data.msg });
            });
        })
        .catch(() => {});
    },
    //翻页操作
    onCurrentChange(e) {
      this.skip = e;
      this.GetAll();
    },
    //重置搜索
    Refresh() {
      //刷新form表单
      this.userId = "";
      this.skip = 1;
      //请求数据
      this.GetAll();
    },
    //选择数据
    handleSelectionChange(e) {
      this.deletes = e;
    },
    //获取信息
    Search() {
      this.GetAll();
    },
    //获取用户信息
    GetAll() {
      this.$axios
        .get(
          `/address/adminGetAddressAll?skip=${this.skip}&userId=${this.userId}`
        )
        .then((res) => {
          this.total = res.data.code;
          this.addresses = res.data.data;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 90%;
  border: 1px solid #e3dfdf86;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .top {
    display: flex;
    justify-content: space-between;
  }
  .bottom {
    margin-top: 30px;
  }
}

.list {
  width: 90%;
  border: 1px solid #e3dfdf86;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .top {
    display: flex;
    justify-content: space-between;
  }
}
.center {
  width: 90%;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .el-table {
    margin: auto;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  .batch {
    display: flex;
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>