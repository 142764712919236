<template>
  <div id="app">
    <router-view></router-view>
    <div id="footerMessage">网站备案号：闽ICP备 2024057220 号</div>
  </div>
</template>

<script>
import defaultRouter from "@/utils/AddRouter";
export default {
  name: "App",
  components: {},
  mounted() {
    //初始化数据
    this.init();
  },
  methods: {
    //判断是否为空
    init() {
      const path = this.$route.path;
      //判断哪个页面
      if (path.startsWith("/index") || path.startsWith("/verify")) {
        let user = localStorage.getItem("user");
        //数据为空
        if (user == null || user == undefined || user == "") {
          this.$router.replace("/login");
          return;
        }
        //加载路由
        defaultRouter();
      }
    },
  },
};
</script>

<style>
#app {
}
#footerMessage {
  position: fixed;
  color: #bdc3c7;
  font-size: 1vh;
  right: 2vh;
  bottom: 2vh;
}
</style>
