<template>
  <div>
    <dialog id="dialog" @click="CloseDialog()">
      <div id="box" @click.stop>
        <div class="header">消息提醒</div>
        <div class="center">
          <div class="report" @click="ToReport()">
            您有 {{ message.report }} 条帖子举报未进行处理。
          </div>
          <div class="audit-clerk" @click="ToAuditClerk()">
            您有 {{ message.auditClerk }} 条接单员审核未进行处理。
          </div>
        </div>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: {
        report: 0, //举报条数
        auditClerk: 0, //接单员审核
      }, //消息提醒处理
    };
  },
  mounted() {
    //初始化操作
    this.init();
  },
  methods: {
    //前往举报操作页面
    ToReport() {
      //关闭遮罩
      this.CloseDialog();
      if (this.$route.path != "/index/report") {
        this.$router.push("/index/report");
        return;
      }
      this.$notify.warning({ message: "正在当前页面" });
    },
    //前往审核页面
    ToAuditClerk() {
      //关闭遮罩
      this.CloseDialog();
      if (this.$route.path != "/index/clerk") {
        this.$router.push("/index/clerk");
        return;
      }
      this.$notify.warning({ message: "正在当前页面" });
    },
    //初始化操作
    init() {
      this.$bus.$on("OpenRightMessageDialog", (message) => {
        this.message = message;
        this.OpenDialog();
      });
    },
    //开启遮罩
    OpenDialog() {
      document.getElementById("dialog").show();
    },
    //关闭遮罩
    CloseDialog() {
      document.getElementById("dialog").close();
    },
  },
};
</script>

<style lang="less" scoped>
#dialog {
  border: none;
  outline: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: #00000042;
  z-index: 9999999;
}
#dialog::backdrop {
  background-color: rgba(95, 95, 95, 0.16);
}

#box {
  width: 30vw;
  height: 100vh;
  background-color: white;
  position: absolute;
  right: 0px;
  top: 0;
  animation: OpenRightMessageDialog 0.3s ease-in-out forwards;
  overflow: auto;
}
@keyframes OpenRightMessageDialog {
  from {
    width: 0px;
  }
  to {
    width: 30vw;
  }
}

.header {
  padding: 20px;
  font-weight: 600;
}

.center {
  width: 28vw;
  text-align: center;
  .report,
  .audit-clerk {
    margin: 20px;
    padding: 20px;
    border: 1px dashed #999999;
    border-radius: 10px;
    cursor: pointer;
  }
}
</style>