<template>
  <div>
    <el-table
      :data="admins"
      border=""
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="排序">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="昵称" prop="userId"></el-table-column>
      <el-table-column label="操作" prop="">
        <template slot-scope="scope">
          <el-button size="mini" @click="Verify(admins[scope.$index].id)"
            >删除管理员</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <div class="batch">
        <div class="left">
          <el-select placeholder="批量操作" clearable="" v-model="batch">
          </el-select>
        </div>
        <div class="right">
          <el-button type="primary" @click="BatchUpdate()">确定</el-button>
        </div>
      </div>
      <div class="page">
        <el-pagination
          @current-change="onCurrentChange"
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      skip: 1, //分页数据查询
      admins: [], //管理员列表
      total: 0, //总条数
      batch: "", //批量操作
    };
  },
  mounted() {
    //获取数据
    this.getAdminInfo();
    //处理事件总线
    this.init();
  },
  methods: {
    //翻页操作
    onCurrentChange(e) {
      this.skip = e;
      this.getAdminInfo();
    },
    //处理事件总线
    init() {
      this.$bus.$on("DeleteAdmin", (id) => {
        this.deleteAdmin(id);
      });
    },
    deleteAdmin(id) {
      //判断userId是否为空
      if (id == null || id == "") {
        return;
      }
      //删除管理员请求
      this.$axios.get(`/user/adminDeleteAdmin?id=${id}`).then((res) => {
        //成功请求
        if (res.data.code == 200) {
          this.$notify.success("删除成功");
          //刷新页面
          this.getAdminInfo();
          return;
        }
        this.$notify.error(res.data.msg);
      });
    },
    //操作表格变化
    handleSelectionChange() {},
    //获取数据
    getAdminInfo() {
      this.$axios
        .get(`/user/adminGetAdminInfo?skip=${this.skip}`)
        .then((res) => {
          this.admins = res.data.data.list;
          this.total = res.data.data.total;
        });
    },
    //添加管理员
    Verify(id) {
      localStorage.setItem("verify", "delete-admin");
      localStorage.setItem("userId", id);
      //验证身份
      this.$router.push("/verify");
    },
  },
};
</script>

<style lang="less" scoped>
.el-table {
  margin: auto;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  .batch {
    display: flex;
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>