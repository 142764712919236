<template>
  <div>
    <el-dialog
      title="编辑订单详情"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="box" v-if="form != null">
        <div class="form">
          <el-form :inline="true">
            <el-form-item label="订单关联ID">
              <el-input clearable="" disabled v-model="form.id"></el-input>
            </el-form-item>

            <el-form-item label="快递代取地址">
              <el-select v-model="form.expressPostAddress" clearable="">
                <el-option value="名扬服务站"></el-option>
                <el-option value="10栋楼下"></el-option>
                <el-option value="邮政快递"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="用户收货地址">
              <el-input
                clearable=""
                type="textarea"
                v-model="form.expressAddress"
              ></el-input>
            </el-form-item>

            <el-form-item label="快递的大小">
              <el-select v-model="form.expressSize" clearable="">
                <el-option value="小件"></el-option>
                <el-option value="中件"></el-option>
                <el-option value="大件"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="快递的数量">
              <el-input
                clearable=""
                type="number"
                v-model="form.expressNum"
              ></el-input>
            </el-form-item>

            <el-form-item label="备注信息">
              <el-input
                type="textarea"
                clearable=""
                v-model="form.serverDescription"
              ></el-input>
            </el-form-item>

            <el-form-item label="快递的取件码">
              <el-input
                clearable=""
                type="textarea"
                v-model="form.expressClaimCode"
              ></el-input>
            </el-form-item>
          </el-form>
          <div v-if="form.expressImage != null && form.expressImage != ''">
            <div>快递图片</div>
            <div>
              <div
                style="
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  margin-top: 50px;
                  flex-wrap: wrap;
                "
              >
                <div v-for="(item, index) in images" :key="index">
                  <div>
                    <el-image
                      v-if="
                        item.endsWith('.png') ||
                        item.endsWith('.jpg') ||
                        item.endsWith('.jpeg') ||
                        item.endsWith('.webp')
                      "
                      style="width: 200px; height: 200px"
                      :src="webURL + item"
                      :preview-src-list="[webURL + item]"
                    >
                    </el-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" type="primary" @click="AdminUpdateUserInfo()"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      form: null, //表单信息
      images: [], //图片地址
      webURL: this.$axios.defaults.webURL, //网站端口路径
    };
  },
  mounted() {
    //获取用户信息
    this.GetUserInfo();
  },
  methods: {
    //修改用户信息
    AdminUpdateUserInfo() {
      this.$axios.post("/server/adminUpdateItOne", this.form).then((res) => {
        this.handleClose();
        //请求数据成功
        if (res.data.code == 200) {
          this.$notify.success({ message: "修改成功" });
          this.$bus.$emit("UpdateReview");
          return;
        }
        this.$notify.error({ message: res.data.msg });
      });
    },
    //获取用户信息
    GetUserInfo() {
      //从本地缓存拿取用户id
      let orderId = localStorage.getItem("orderId");
      //判断是否有值
      if (orderId == null || orderId == undefined || orderId == "") {
        this.handleClose();
        return;
      }
      //删除缓存值
      localStorage.removeItem("orderId");
      //获取用户信息
      this.$axios
        .get(`/server/adminGetServerOrderOne?id=${orderId}&serverType=快递代取`)
        .then((res) => {
          this.form = res.data.data;
          //截取图片数据
          this.images = this.form.expressImage.split(",");
        });
    },
    //路由回退
    handleClose() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .form {
    .el-form {
      .el-form-item {
      }
    }
  }
  .button {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
#dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.785);
}
</style>