<template>
  <div>
    <el-dialog
      title="修改通知信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="box" v-if="form != null">
        <div class="form">
          <el-form :inline="true" label-width="100px">
            <el-form-item label="日志ID">
              <el-input clearable="" disabled v-model="form.id"></el-input>
            </el-form-item>

            <el-form-item label="订单ID">
              <el-input
                clearable=""
                disabled
                v-model="form.serverId"
              ></el-input>
            </el-form-item>

            <el-form-item label="转账金额">
              <el-input
                clearable=""
                disabled
                v-model="form.serverAllMoney"
              ></el-input>
            </el-form-item>
            <el-form-item label="时间">
              <el-input
                clearable=""
                type="textarea"
                v-model="form.payTime"
              ></el-input>
            </el-form-item>

            <el-form-item label="转账说明">
              <el-input clearable="" v-model="form.serverPayType"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="button">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" type="warning" @click="ToLookServer"
            >查看订单</el-button
          >
          <el-button size="mini" type="primary" @click="handleClose"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      form: null, //表单信息
    };
  },
  mounted() {
    //获取用户信息
    this.GetUserInfo();
  },
  methods: {
    //查看通知用户详情
    ToLookServer() {
      localStorage.setItem("serverId", this.form.serverId);
      this.$router.push("/index/order/update");
      localStorage.setItem("payLogId", this.form.id);
    },
    //获取信息
    GetUserInfo() {
      //从本地缓存拿取用户id
      let payLogId = localStorage.getItem("payLogId");
      //判断是否有值
      if (payLogId == null || payLogId == undefined || payLogId == "") {
        this.handleClose();
        return;
      }
      //删除缓存值
      localStorage.removeItem("payLogId");
      //获取用户信息
      this.$axios
        .get(`/server/adminGetPayLogUpdate?id=${payLogId}`)
        .then((res) => {
          this.form = res.data.data;
        });
    },
    //路由回退
    handleClose() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .form {
    .el-form {
      .el-form-item {
      }
    }
  }
  .button {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
#dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.785);
}
</style>