<template>
  <div>
    <div class="header">
      <div class="top">
        <div class="left">
          <i class="el-icon-search"></i>
          <span> 筛选搜索</span>
        </div>
        <div class="right">
          <el-button size="mini" icon="el-icon-refresh" @click="Refresh()"
            >重置搜索</el-button
          >
          <el-button
            size="mini"
            icon="el-icon-search"
            type="primary"
            @click="Search()"
            >查询结果</el-button
          >
        </div>
      </div>
      <div class="bottom">
        <el-form inline>
          <el-row>
            <el-col :span="8">
              <el-form-item label="帖子ID：">
                <el-input clearable v-model="form.id"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="发帖用户ID：">
                <el-input clearable v-model="form.publishId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="帖子搜索：">
                <el-input clearable v-model="form.search"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="帖子话题：">
                <el-select v-model="form.topicality" clearable>
                  <el-option value="每日吐槽"></el-option>
                  <el-option value="表白时刻"></el-option>
                  <el-option value="跳蚤市场"></el-option>
                  <el-option value="学习心得"></el-option>
                  <el-option value="美食派对"></el-option>
                  <el-option value="科技数码"></el-option>
                  <el-option value="摄影发现"></el-option>
                  <el-option value="旅行分享"></el-option>
                  <el-option value="另有乾坤"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="帖子状态：">
                <el-select clearable v-model="form.status">
                  <el-option value="未删除"></el-option>
                  <el-option value="待删除"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="帖子排序：">
                <el-select clearable v-model="form.sort">
                  <el-option value="最新排序"></el-option>
                  <el-option value="热门排序"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="发帖时间：">
                <el-date-picker
                  clearable
                  v-model="signTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始范围"
                  end-placeholder="结束范围"
                  align="right"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="list">
      <div class="top">
        <div class="left">
          <i class="el-icon-tickets"></i>
          <span> 数据列表</span>
        </div>
        <div class="right">
          <el-button
            size="mini"
            icon="el-icon-delete"
            type="danger"
            @click="DeletePostStatus()"
            >清空待删除所保留的数据</el-button
          >
        </div>
      </div>
    </div>
    <div class="center">
      <el-table
        :data="posts"
        border=""
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="排序">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="话题" prop="topicality"></el-table-column>
        <el-table-column label="发帖时间" prop="time"></el-table-column>
        <el-table-column label="操作" prop="">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="UpdateUserInfo(posts[scope.$index].id)"
              >编辑</el-button
            >
            <el-button size="mini" @click="DeletePost(posts[scope.$index].id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <div class="batch">
        <div class="left">
          <el-select placeholder="批量操作" clearable="" v-model="batch">
          </el-select>
        </div>
        <div class="right">
          <el-button type="primary" @click="BatchUpdate()">确定</el-button>
        </div>
      </div>
      <div class="page">
        <el-pagination
          @current-change="onCurrentChange"
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        id: "", //帖子id
        publishId: "", //发表用户的id
        search: "", //搜索内容
        topicality: "", //话题选择
        sort: "最新排序", //排序内容
        status: "", //帖子状态
        skip: 1, //翻页数据
        startTime: "", //
        endTime: "", //结束时间
      }, //搜索表单数据
      posts: [], //帖子数据列表
      total: 0, //条数信息
      signTime: null, //注册时间
      batch: "", //用户选择操作
    };
  },
  mounted() {
    //查询数据
    this.GetAllUserInfo();
    //初始化数据
    this.init();
  },
  beforeDestroy() {},
  methods: {
    //清空待保留的数据信息
    DeletePostStatus() {
      //询问用户是否确定删除
      this.$confirm("该操作不可逆，将清空所有待删除数据！", "水院论坛", {
        type: "warning",
      })
        .then(() => {
          //发起请求
          this.$axios.get("/post/adminDeletePostAllStatus").then((res) => {
            // 删除成功
            if (res.data.code == 200) {
              this.$notify.success({ message: "删除成功" });
              this.GetAllUserInfo();
              return;
            }
            this.$notify.error({ message: res.data.msg });
          });
        })
        .catch(() => {
          this.$notify.warning({ message: "危险操作已取消" });
        });
    },
    //初始化数据
    init() {
      //重新请求数据
      this.$bus.$on("UpdatePostWidthDetail", () => {
        this.GetAllUserInfo();
      });
    },
    //展示弹窗信息
    UpdateUserInfo(id) {
      localStorage.setItem("postId", id);
      this.$router.push("/index/post/update");
    },
    //批量操作
    BatchUpdate() {},
    //删除操作
    DeletePost(id) {
      //询问是否确定删除
      this.$confirm(
        "该操作不可逆，将清空所有有关该帖子的数据！",
        "水院论坛",
        {
          type: "warning",
        }
      )
        .then(() => {
          //发起请求
          this.$axios
            .get(`/post/adminDeletePostOne?postId=${id}`)
            .then((res) => {
              // 删除成功
              if (res.data.code == 200) {
                this.$notify.success({ message: "删除成功" });
                this.GetAllUserInfo();
                return;
              }
              this.$notify.error({ message: res.data.msg });
            });
        })
        .catch(() => {
          this.$notify.warning({ message: "危险操作已取消" });
        });
    },
    //翻页操作
    onCurrentChange(e) {
      this.form.skip = e;
      this.GetAllUserInfo();
    },
    //重置搜索
    Refresh() {
      //刷新form表单
      let key = Object.keys(this.form);
      key.forEach((item) => {
        this.form[item] = "";
      });
      this.form.skip = 1;
      this.signTime = "";
      //请求数据
      this.GetAllUserInfo();
    },
    //选择数据
    handleSelectionChange() {},
    //获取信息
    Search() {
      //查询时间数据
      if (this.signTime != null) {
        this.form.startTime = this.signTime[0];
        this.form.endTime = this.signTime[1];
      }
      this.GetAllUserInfo();
    },
    //获取用户信息
    GetAllUserInfo() {
      this.$axios.post("/post/adminGetPost", this.form).then((res) => {
        this.total = res.data.code;
        this.posts = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 90%;
  border: 1px solid #e3dfdf86;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .top {
    display: flex;
    justify-content: space-between;
  }
  .bottom {
    margin-top: 30px;
  }
}

.list {
  width: 90%;
  border: 1px solid #e3dfdf86;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .top {
    display: flex;
    justify-content: space-between;
  }
}
.center {
  width: 90%;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .el-table {
    margin: auto;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  .batch {
    display: flex;
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>