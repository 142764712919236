<template>
  <div>
    <el-page-header @back="goBack" content="登录页面"> </el-page-header>
    <div class="box" v-if="!showSuccess">
      <div class="title">
        <svg
          t="1714393436976"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4322"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="30"
          height="30"
        >
          <path
            d="M352.814545 385.396364m-33.512727 0a33.512727 33.512727 0 1 0 67.025455 0 33.512727 33.512727 0 1 0-67.025455 0Z"
            fill="#50B674"
            p-id="4323"
          ></path>
          <path
            d="M502.690909 384.465455m-33.512727 0a33.512727 33.512727 0 1 0 67.025454 0 33.512727 33.512727 0 1 0-67.025454 0Z"
            fill="#50B674"
            p-id="4324"
          ></path>
          <path
            d="M576.232727 534.341818m-23.272727 0a23.272727 23.272727 0 1 0 46.545455 0 23.272727 23.272727 0 1 0-46.545455 0Z"
            fill="#50B674"
            p-id="4325"
          ></path>
          <path
            d="M694.458182 536.203636m-23.272727 0a23.272727 23.272727 0 1 0 46.545454 0 23.272727 23.272727 0 1 0-46.545454 0Z"
            fill="#50B674"
            p-id="4326"
          ></path>
          <path
            d="M512 0C229.003636 0 0 229.003636 0 512s229.003636 512 512 512 512-229.003636 512-512S794.996364 0 512 0z m-87.505455 630.225455c-26.996364 0-48.407273-5.585455-75.403636-11.17091l-75.403636 37.236364 21.410909-64.232727c-53.992727-37.236364-85.643636-85.643636-85.643637-145.221818 0-102.4 96.814545-182.458182 215.04-182.458182 105.192727 0 198.283636 64.232727 216.901819 150.807273-6.516364-0.930909-13.963636-0.930909-20.48-0.93091-102.4 0-182.458182 76.334545-182.458182 170.356364 0 15.825455 2.792727 30.72 6.516363 44.683636-7.447273 0-13.963636 0.930909-20.48 0.93091z m314.647273 75.403636l15.825455 53.992727-58.647273-32.581818c-21.410909 5.585455-42.821818 11.170909-64.232727 11.170909-102.4 0-182.458182-69.818182-182.458182-155.461818s80.058182-155.461818 182.458182-155.461818c96.814545 0 182.458182 69.818182 182.458182 155.461818 0 47.476364-31.650909 90.298182-75.403637 122.88z"
            fill="#50B674"
            p-id="4327"
          ></path></svg
        >使用微信小程序扫一扫登录
      </div>
      <div
        class="img"
        v-if="qrcodeImage != null && tip != '二维码已过期，请重新获取二维码'"
      >
        <img :src="qrcodeImage" alt="" />
      </div>
      <div class="img" v-else style="background-color: black"></div>
      <div class="tip">{{ tip }}</div>
    </div>
    <div class="box" v-else>
      <el-result icon="success" title="成功提示" subTitle="扫码登录成功">
        <template slot="extra">
          <el-button type="primary" size="medium" @click="ToIndex()"
            >进入主页 {{ time }}</el-button
          >
        </template>
      </el-result>
    </div>
    <SocketConn></SocketConn>
  </div>
</template>

<script>
import SocketConn from "@/components/websocket/SocketConn.vue";
import defaultRouter from "@/utils/AddRouter";
import { Encrypt } from "@/utils/secret.js";
export default {
  components: { SocketConn },
  data() {
    return {
      qrcode: null, //用来存放二维码数据
      qrcodeImage: null, //二维码数据
      showSuccess: false, //是否扫码成功
      time: 3, //秒后跳转主页
      tip: `请在两分钟内扫码登录【${this.$baseConfig.title}管理端】`, //提示用户的词语
    };
  },
  mounted() {
    //初始化数据
    this.init();
    //监听是否断开连接
    this.IfSocketCloseOrSuccess();
  },
  beforeDestroy() {
    this.$bus.$off();
  },
  methods: {
    //初始化数据
    init() {
      //赋值
      this.qrcode = this.$store.getters.GetQrcode;
      //判断是否为null
      if (this.qrcode == null) {
        this.goBack();
        return;
      }
      //开始进行渲染图片并连接websocket
      const url = URL.createObjectURL(this.qrcode.blob);
      this.qrcodeImage = url;
      this.ConnSocket();
    },
    //进行websocket远程连接
    ConnSocket() {
      this.$bus.$emit("ConnSocket", this.qrcode.userTempId);
    },
    //连接断开或登陆成功处理
    IfSocketCloseOrSuccess() {
      this.$bus.$on("ConnSocketClose", () => {
        this.tip = "二维码已过期，请重新获取二维码";
      });
      this.$bus.$on("LoginSuccess", (data) => {
        //判断是否已经提示
        if (this.showSuccess) {
          return;
        }
        this.showSuccess = true;
        //清楚临时数据
        localStorage.clear();
        //设置用户数据
        localStorage.setItem("user", JSON.stringify(data.data));
        localStorage.setItem("token", Encrypt(Encrypt(data.message)));
        //动态加载路由
        this.AddRouter();
        //三秒后跳转
        let timeVal = setInterval(() => {
          if (this.time == 0) {
            //清楚定时器跳转
            clearInterval(timeVal);
            if (this.$route.path == "/qrcode") {
              //跳转
              this.ToIndex();
            }
            return;
          }
          this.time--;
        }, 1000);
      });
    },
    //动态添加路由
    AddRouter() {
      defaultRouter();
    },
    //返回登录页面
    goBack() {
      if (this.$route.path != "/login") {
        this.$router.replace("/login");
      }
    },
    //前往主页
    ToIndex() {
      if (this.$route.path != "/index/home") {
        this.$router.replace("/index/home");
      }
    },
  },
  //路由离开
  beforeRouteLeave(form, to, next) {
    if (this.$websocket != null) {
      this.$websocket.close();
    }
    next();
  },
};
</script>

<style lang="less" scoped>
.box {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  text-align: center;
  font-size: 16px;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 20px;
    }
  }
  .img {
    width: 200px;
    height: 200px;
    margin: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tip {
    text-align: center;
    font-size: 14px;
  }
}
</style>