<template>
  <div>
    <div class="box">
      <div class="title">登录</div>
      <div class="introduce">
        <div>{{ $baseConfig.title }}·智慧流域</div>
      </div>
      <div class="button">
        <button @click="ToQrcode()">
          <svg
            t="1714393436976"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4322"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="30"
            height="30"
          >
            <path
              d="M352.814545 385.396364m-33.512727 0a33.512727 33.512727 0 1 0 67.025455 0 33.512727 33.512727 0 1 0-67.025455 0Z"
              fill="#50B674"
              p-id="4323"
            ></path>
            <path
              d="M502.690909 384.465455m-33.512727 0a33.512727 33.512727 0 1 0 67.025454 0 33.512727 33.512727 0 1 0-67.025454 0Z"
              fill="#50B674"
              p-id="4324"
            ></path>
            <path
              d="M576.232727 534.341818m-23.272727 0a23.272727 23.272727 0 1 0 46.545455 0 23.272727 23.272727 0 1 0-46.545455 0Z"
              fill="#50B674"
              p-id="4325"
            ></path>
            <path
              d="M694.458182 536.203636m-23.272727 0a23.272727 23.272727 0 1 0 46.545454 0 23.272727 23.272727 0 1 0-46.545454 0Z"
              fill="#50B674"
              p-id="4326"
            ></path>
            <path
              d="M512 0C229.003636 0 0 229.003636 0 512s229.003636 512 512 512 512-229.003636 512-512S794.996364 0 512 0z m-87.505455 630.225455c-26.996364 0-48.407273-5.585455-75.403636-11.17091l-75.403636 37.236364 21.410909-64.232727c-53.992727-37.236364-85.643636-85.643636-85.643637-145.221818 0-102.4 96.814545-182.458182 215.04-182.458182 105.192727 0 198.283636 64.232727 216.901819 150.807273-6.516364-0.930909-13.963636-0.930909-20.48-0.93091-102.4 0-182.458182 76.334545-182.458182 170.356364 0 15.825455 2.792727 30.72 6.516363 44.683636-7.447273 0-13.963636 0.930909-20.48 0.93091z m314.647273 75.403636l15.825455 53.992727-58.647273-32.581818c-21.410909 5.585455-42.821818 11.170909-64.232727 11.170909-102.4 0-182.458182-69.818182-182.458182-155.461818s80.058182-155.461818 182.458182-155.461818c96.814545 0 182.458182 69.818182 182.458182 155.461818 0 47.476364-31.650909 90.298182-75.403637 122.88z"
              fill="#50B674"
              p-id="4327"
            ></path>
          </svg>
          <span>微信小程序扫码登录</span>
          <svg
            v-if="showLoding"
            t="1714394117332"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="5491"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="20"
            height="20"
          >
            <path
              d="M546.462897 266.292966c-73.410207 0-133.15531-59.727448-133.155311-133.137656C413.307586 59.762759 473.05269 0 546.462897 0c73.410207 0 133.12 59.727448 133.12 133.15531 0 73.410207-59.709793 133.137655-133.12 133.137656z m-283.453794 105.736827c-67.054345 0-121.626483-54.554483-121.626482-121.644138s54.572138-121.644138 121.626482-121.644138a121.767724 121.767724 0 0 1 121.608828 121.644138c0 67.054345-54.554483 121.644138-121.608828 121.644138zM142.547862 647.185655A107.343448 107.343448 0 0 1 35.310345 539.895172a107.343448 107.343448 0 0 1 107.237517-107.237517 107.343448 107.343448 0 0 1 107.219862 107.237517 107.343448 107.343448 0 0 1-107.219862 107.272828z m120.461241 272.595862a91.047724 91.047724 0 0 1-90.941793-90.959448 91.065379 91.065379 0 0 1 90.924138-90.941793 91.065379 91.065379 0 0 1 90.941793 90.941793c0 50.14069-40.783448 90.959448-90.924138 90.959448zM546.462897 1024a79.518897 79.518897 0 0 1-79.448276-79.448276c0-43.820138 35.645793-79.448276 79.448276-79.448276a79.518897 79.518897 0 0 1 79.43062 79.448276c0 43.820138-35.628138 79.448276-79.448276 79.448276z m287.744-134.285241a64.194207 64.194207 0 0 1-64.123587-64.123587 64.194207 64.194207 0 0 1 64.123587-64.123586 64.194207 64.194207 0 0 1 64.123586 64.123586 64.194207 64.194207 0 0 1-64.123586 64.123587z m117.848275-296.695173a52.683034 52.683034 0 0 1-52.612413-52.612414 52.683034 52.683034 0 0 1 52.612413-52.630069 52.70069 52.70069 0 0 1 52.630069 52.612414 52.718345 52.718345 0 0 1-52.630069 52.630069z m-158.667034-338.696827a40.818759 40.818759 0 1 0 81.655172 0.017655 40.818759 40.818759 0 0 0-81.655172 0z"
              fill="#389BFF"
              p-id="5492"
            ></path>
          </svg>
        </button>
      </div>
      <div class="server">
        <div class="tip">
          点击上面按钮，代表你同意{{ $baseConfig.title }}的<span
            @click="ToServer()"
            style="cursor: pointer"
            >服务条款</span
          >
        </div>
        <div class="img">
          <el-image
            :src="require('@/assets/home/qrcode.jpg')"
            :preview-src-list="[require('@/assets/home/qrcode.jpg')]"
          ></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLoding: false, //是否展示正在加载的二维码
    };
  },
  mounted() {
    //清楚默认数据
    localStorage.clear();
  },
  methods: {
    //服务条款
    ToServer() {
      this.$notify.warning({
        message: `详情参见微信小程序“${this.$baseConfig.title}”小程序`,
      });
    },
    //前往二维码页面
    ToQrcode() {
      //生成随机数确定用户
      let userTempId = this.generateRandomString(31);
      //开始加载
      this.showLoding = true;
      //请求数据
      this.$axios
        .get(`/qrcode/adminGetQrcode?userTempId=${userTempId}`, {
          responseType: "blob",
        })
        .then((res) => {
          //把接收blob数据
          const blob = new Blob([res.data], { type: res.data.type });
          //存入用户信息携带至二维码页面
          const qrcode = {
            userTempId: userTempId,
            blob: blob,
          };
          //设置进入vuex
          this.$store.commit("SetQrcode", qrcode);
          //取消加载
          this.showLoding = false;
          //跳转
          this.$router.push("/qrcode");
        });
    },
    //创建随机数
    generateRandomString(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  text-align: center;
  font-size: 16px;
  .title {
    font-weight: 600;
    font-size: 40px;
  }
  .introduce {
    padding: 20px;
    color: #877a7a;
  }
  .button {
    padding: 20px;
    button {
      display: flex;
      align-items: center;
      background-color: white;
      border: 1px solid rgb(220, 217, 217);
      padding: 5px 20px;
      cursor: pointer;
      span {
        margin: 0px 20px;
      }
      margin: auto;
    }
  }
  .server {
    display: flex;
    align-items: center;
    .tip {
      font-size: 14px;
      margin-top: 20px;
      span {
        color: #3b82f6;
        border-bottom: 1px solid;
      }
    }
    .img {
      width: 50px;
      height: 50px;
      margin-top: 20px;
      margin-left: 30px;
    }
  }
}
</style>