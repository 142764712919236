import router from "@/router";

import PageIndex from "@/pages/index/PageIndex.vue";
import VerifyAdmin from "@/pages/qrcode/VerifyAdmin.vue";
import UserIndex from "@/components/index/user/UserIndex.vue";
import ManageIndex from "@/components/index/user/ManageIndex.vue";
import UpdateUserInfo from "@/components/index/user/UpdateUserInfo.vue";
import PostIndex from "@/components/index/post/PostIndex.vue";
import UpdatePost from "@/components/index/post/UpdatePost.vue";
import PostComment from "@/components/index/post/comment/PostComment.vue";
import UpdateComment from "@/components/index/post/comment/UpdateComment.vue";
import PostReview from "@/components/index/post/review/PostReview.vue";
import UpdateReview from "@/components/index/post/review/UpdateReview.vue";
import PostCollect from "@/components/index/post/collect/PostCollect.vue";
import UpdateCollect from "@/components/index/post/collect/UpdateCollect.vue";
import PostLove from "@/components/index/post/love/PostLove.vue";
import PostMessage from "@/components/index/post/message/PostMessage.vue";
import UpdateMessage from "@/components/index/post/message/UpdateMessage.vue";
import PostReport from "@/components/index/post/report/PostReport.vue";
import UpdateReport from "@/components/index/post/report/UpdateReport.vue";
import SystemIndex from "@/components/index/system/SystemIndex.vue";
import ClerkIndex from "@/components/index/clerk/ClerkIndex.vue";
import UpdateClerk from "@/components/index/clerk/UpdateClerk.vue";
import AddressIndex from "@/components/index/address/AddressIndex.vue";
import UpdateAddress from "@/components/index/address/UpdateAddress.vue";
import ServerIndex from "@/components/index/server/ServerIndex.vue";
import UpdateServer from "@/components/index/server/UpdateServer.vue";
import UpdateEat from "@/components/index/server/eat/UpdateEat.vue";
import UpdateBuy from "@/components/index/server/buy/UpdateBuy.vue";
import UpdateReplace from "@/components/index/server/replace/UpdateReplace.vue";
import UpdateGame from "@/components/index/server/game/UpdateGame.vue";
import UpdateIt from "@/components/index/server/it/UpdateIt.vue";
import UpdateExpress from "@/components/index/server/express/UpdateExpress.vue";
import HomeIndex from "@/components/index/home/HomeIndex.vue";
import PayLog from "@/components/index/paylog/PayLog.vue";
import PayLogUpdate from "@/components/index/paylog/PayLogUpdate.vue";
import ProductionIndex from "@/components/index/production/ProductionIndex.vue";
import UpdateProduction from "@/components/index/production/UpdateProduction.vue";

const defaultRouter = () => {
  // 直接创建一个路由配置对象，而不是先添加到数组中
  let routes = [
    {
      path: "/index",
      component: PageIndex,
      meta: { title: "列表" },
      children: [
        {
          path: "home",
          meta: { title: "主页" },
          component: HomeIndex,
        },
        {
          path: "user",
          meta: { title: "用户" },
          component: UserIndex,
          children: [
            {
              path: "update",
              component: UpdateUserInfo,
            },
          ],
        },
        {
          path: "manage",
          meta: { title: "管理员" },
          component: ManageIndex,
        },
        {
          path: "post",
          meta: { title: "帖子" },
          component: PostIndex,
          children: [
            {
              path: "update",
              component: UpdatePost,
            },
          ],
        },
        {
          path: "comment",
          meta: { title: "评论" },
          component: PostComment,
          children: [
            {
              path: "update",
              component: UpdateComment,
            },
          ],
        },
        {
          path: "review",
          meta: { title: "回复" },
          component: PostReview,
          children: [
            {
              path: "update",
              component: UpdateReview,
            },
          ],
        },
        {
          path: "collect",
          meta: { title: "收藏" },
          component: PostCollect,
          children: [
            {
              path: "update",
              component: UpdateCollect,
            },
          ],
        },
        {
          path: "loves",
          meta: { title: "喜欢" },
          component: PostLove,
        },
        {
          path: "message",
          meta: { title: "消息" },
          component: PostMessage,
          children: [
            {
              path: "update",
              component: UpdateMessage,
            },
          ],
        },
        {
          path: "report",
          meta: { title: "举报" },
          component: PostReport,
          children: [
            {
              path: "update",
              component: UpdateReport,
            },
          ],
        },
        {
          path: "system",
          meta: { title: "管理员操作日志" },
          component: SystemIndex,
        },
        {
          path: "clerk",
          meta: { title: "接单员" },
          component: ClerkIndex,
          children: [
            {
              path: "update",
              component: UpdateClerk,
            },
          ],
        },
        {
          path: "address",
          meta: { title: "地址" },
          component: AddressIndex,
          children: [
            {
              path: "update",
              component: UpdateAddress,
            },
          ],
        },
        {
          path: "order",
          meta: { title: "订单" },
          component: ServerIndex,
          children: [
            {
              path: "update",
              component: UpdateServer,
            },
            {
              path: "eat",
              component: UpdateEat,
            },
            {
              path: "buy",
              component: UpdateBuy,
            },
            {
              path: "replace",
              component: UpdateReplace,
            },
            {
              path: "game",
              component: UpdateGame,
            },
            {
              path: "it",
              component: UpdateIt,
            },
            {
              path: "express",
              component: UpdateExpress,
            },
          ],
        },
        {
          path: "paylog",
          meta: { title: "支付日志" },
          component: PayLog,
          children: [
            {
              path: "update",
              component: PayLogUpdate,
            },
          ],
        },
        {
          path: "production",
          meta: { title: "作品" },
          component: ProductionIndex,
          children: [
            {
              path: "update",
              component: UpdateProduction,
            },
          ],
        },
      ],
    },
    {
      path: "/verify",
      component: VerifyAdmin,
    },
  ];
  //遍历循环添加
  routes.forEach((item) => {
    router.addRoute(item);
  });
};

export default defaultRouter;
