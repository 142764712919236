<template>
  <div>
    <el-dialog
      title="修改用户地址信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="box" v-if="form != null">
        <div class="form">
          <el-form :inline="true">
            <el-form-item label="地址ID">
              <el-input clearable="" disabled v-model="form.id"></el-input>
            </el-form-item>

            <el-form-item label="用户ID">
              <el-input clearable="" disabled v-model="form.userId"></el-input>
            </el-form-item>
            <el-form-item label="地址信息">
              <el-input
                clearable=""
                type="textarea"
                style="width: 400px"
                rows="10"
                v-model="form.address"
              ></el-input>
            </el-form-item>

            <el-form-item label="地址信息">
              <div
                style="display: flex; justify-content: center; margin: auto"
                v-for="(item, index) in address"
                :key="index"
              >
                <AddressItem :item="item"></AddressItem>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="button">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" type="warning" @click="ToLookUser()"
            >用户信息</el-button
          >
          <el-button size="mini" type="primary" @click="AdminUpdateUserInfo()"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AddressItem from "@/components/index/address/AddressItem.vue";
export default {
  components: { AddressItem },
  data() {
    return {
      dialogVisible: true, //展示弹窗
      form: null, //表单信息
      webURL: this.$axios.defaults.webURL, //网站端口路径
      address: [], //数据信息
    };
  },
  mounted() {
    //获取用户信息
    this.GetUserInfo();
  },
  methods: {
    //查看用户信息
    ToLookUser() {
      localStorage.setItem("userId", this.form.userId);
      this.$router.push("/index/user/update");
      localStorage.setItem("addressId", this.form.id);
    },
    //修改用户信息
    AdminUpdateUserInfo() {
      this.$axios.post("/address/adminUpdateAddress", this.form).then((res) => {
        this.handleClose();
        //请求数据成功
        if (res.data.code == 200) {
          this.$notify.success({ message: "修改成功" });
          this.$bus.$emit("UpdateClerk");
          return;
        }
        this.$notify.error({ message: res.data.msg });
      });
    },
    //获取用户信息
    GetUserInfo() {
      //从本地缓存拿取用户id
      let addressId = localStorage.getItem("addressId");
      //判断是否有值
      if (addressId == null || addressId == undefined || addressId == "") {
        this.handleClose();
        return;
      }
      //删除缓存值
      localStorage.removeItem("addressId");
      //获取用户信息
      this.$axios
        .get(`/address/adminGetAddress?id=${addressId}`)
        .then((res) => {
          this.form = res.data.data;
          this.address = JSON.parse(this.form.address);
        });
    },
    //路由回退
    handleClose() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  overflow: auto;
  .form {
    .el-form {
      .el-form-item {
      }
    }
  }
  .button {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
#dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.785);
}
</style>