<template>
  <div>
    <div class="list">
      <div class="top">
        <div class="left">
          <i class="el-icon-tickets"></i>
          <span> 数据列表</span>
        </div>
        <div class="right">
          <el-button type="danger" plain size="mini" @click="ClearLog()"
            >清空日志</el-button
          >
        </div>
      </div>
    </div>
    <div class="center">
      <el-table :data="messages" border="">
        <el-table-column label="排序">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="sysUserId"></el-table-column>
        <el-table-column label="操作内容" prop="sysContent"></el-table-column>
        <el-table-column label="操作路径" prop="sysUrl"></el-table-column>
        <el-table-column label="操作时间" prop="sysTime"></el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <div class="page">
        <el-pagination
          @current-change="onCurrentChange"
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      skip: 1, //分页数据
      messages: [], //帖子数据列表
      total: 0, //条数信息
    };
  },
  mounted() {
    //查询数据
    this.GetAll();
  },
  beforeDestroy() {},
  methods: {
    //清空日志
    ClearLog() {
      this.$notify.warning({ message: "日志定期清理，无法强制清楚！" });
    },
    //翻页操作
    onCurrentChange(e) {
      this.skip = e;
      this.GetAll();
    },
    //获取信息
    GetAll() {
      this.$axios
        .get("/system/adminGetSystem?skip=" + this.skip)
        .then((res) => {
          this.total = res.data.code;
          this.messages = res.data.data;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 90%;
  border: 1px solid #e3dfdf86;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .top {
    display: flex;
    justify-content: space-between;
  }
  .bottom {
    margin-top: 30px;
  }
}

.list {
  width: 90%;
  border: 1px solid #e3dfdf86;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .top {
    display: flex;
    justify-content: space-between;
  }
}
.center {
  width: 90%;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .el-table {
    margin: auto;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  .batch {
    display: flex;
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>