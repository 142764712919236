<template>
  <div>
    <el-dialog
      title="修改帖子信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="box" v-if="form != null">
        <div class="form">
          <el-form :inline="true" label-width="100px">
            <el-form-item label="帖子ID">
              <el-input clearable="" disabled v-model="form.post.id"></el-input>
            </el-form-item>

            <el-form-item label="帖子详情ID">
              <el-input
                clearable=""
                disabled
                v-model="form.detail.id"
              ></el-input>
            </el-form-item>

            <el-form-item label="帖子标题">
              <el-input clearable="" v-model="form.post.title"></el-input>
            </el-form-item>

            <el-form-item label="帖子介绍">
              <el-input clearable="" v-model="form.post.introduce"></el-input>
            </el-form-item>

            <el-form-item label="观看量">
              <el-input clearable="" v-model="form.post.look"></el-input>
            </el-form-item>

            <el-form-item label="点赞数">
              <el-input clearable="" v-model="form.post.love"></el-input>
            </el-form-item>

            <el-form-item label="帖子状态">
              <el-select v-model="form.post.status" clearable>
                <el-option value="未删除"></el-option>
                <el-option value="待删除"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="发表时间">
              <el-input clearable="" v-model="form.post.time"></el-input>
            </el-form-item>

            <el-form-item label="帖子话题">
              <el-select v-model="form.post.topicality" clearable>
                <el-option value="每日吐槽"></el-option>
                <el-option value="表白时刻"></el-option>
                <el-option value="跳蚤市场"></el-option>
                <el-option value="学习心得"></el-option>
                <el-option value="美食派对"></el-option>
                <el-option value="科技数码"></el-option>
                <el-option value="摄影发现"></el-option>
                <el-option value="旅行分享"></el-option>
                <el-option value="另有乾坤"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="发表用户ID">
              <el-input
                clearable=""
                disabled
                v-model="form.post.userId"
              ></el-input>
            </el-form-item>
          </el-form>
          <div
            style="
              display: flex;
              justify-content: space-around;
              align-items: center;
            "
          >
            <div>帖子详情</div>
            <el-input
              style="margin-left: 20px; width: 80%"
              type="textarea"
              clearable=""
              rows="5"
              v-model="form.detail.content"
            ></el-input>
          </div>
          <div
            v-if="form.detail.img != null && form.detail.img != ''"
            style="
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin-top: 20px;
            "
          >
            <div>图片路径</div>
            <el-input
              style="margin-left: 20px; width: 80%"
              type="textarea"
              clearable=""
              rows="5"
              disabled
              v-model="form.detail.img"
            ></el-input>
          </div>
          <div v-if="form.detail.img != null && form.detail.img != ''">
            <div
              style="
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-top: 50px;
                flex-wrap: wrap;
              "
            >
              <div v-for="(item, index) in images" :key="index">
                <div>
                  <el-image
                    v-if="
                      item.endsWith('.png') ||
                      item.endsWith('.jpg') ||
                      item.endsWith('.jpeg') ||
                      item.endsWith('.webp')
                    "
                    style="width: 200px; height: 200px"
                    :src="webURL + item"
                    :preview-src-list="[webURL + item]"
                  >
                  </el-image>
                  <video
                    v-if="item.endsWith('.mp3') || item.endsWith('.mp4')"
                    :src="webURL + item"
                    style="width: 200px; height: 200px"
                    controls
                  ></video>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" type="warning" @click="ToLookUser"
            >查看用户</el-button
          >
          <el-button size="mini" type="danger" @click="DeletePost"
            >删除</el-button
          >
          <el-button size="mini" type="primary" @click="AdminUpdateUserInfo()"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      form: null, //表单信息
      images: [], //图片数据
      webURL: this.$axios.defaults.webURL, //网站端口路径
    };
  },
  mounted() {
    //获取用户信息
    this.GetUserInfo();
  },
  methods: {
    //查看用户详情
    ToLookUser() {
      localStorage.setItem("userId", this.form.post.userId);
      this.$router.push("/index/user/update");
      localStorage.setItem("postId", this.form.post.id);
    },
    //删除操作
    DeletePost() {
      //询问是否确定删除
      this.$confirm("该操作不可逆，将清空所有有关该帖子的数据！", "水院论坛", {
        type: "warning",
      })
        .then(() => {
          //发起请求
          this.$axios
            .get(`/post/adminDeletePostOne?postId=${this.form.id}`)
            .then((res) => {
              // 删除成功
              if (res.data.code == 200) {
                this.$notify.success({ message: "删除成功" });
                this.GetAllUserInfo();
                return;
              }
              this.$notify.error({ message: res.data.msg });
            });
        })
        .catch(() => {
          this.$notify.warning({ message: "危险操作已取消" });
        });
    },
    //修改用户信息
    AdminUpdateUserInfo() {
      //判断帖子的观看量和点赞数是否异常
      const regex = /^\d{1,7}$/; // 匹配1到7位数字的正则表达式，即0到9999999
      // 检查观看量
      if (!regex.test(this.form.post.look)) {
        this.$notify.error({ message: "帖子观看量异常" });
        return;
      }
      // 检查点赞数
      if (!regex.test(this.form.post.love)) {
        this.$notify.error({ message: "帖子点赞数异常" });
        return;
      }
      //帖子的话题异常
      if (this.form.post.topicality == "") {
        this.$notify.error({ message: "帖子话题为空" });
        return;
      }
      //帖子状态修改为0或1
      if (this.form.post.status == "未删除") {
        this.form.post.status = 0;
      } else if (this.form.post.status == "待删除") {
        this.form.post.status = 1;
      } else {
        this.$notify.error({ message: "帖子状态异常" });
        return;
      }
      this.$axios
        .post("/post/adminUpdatePostWidthDetail", this.form)
        .then((res) => {
          this.handleClose();
          //请求数据成功
          if (res.data.code == 200) {
            this.$notify.success({ message: "修改成功" });
            this.$bus.$emit("UpdatePostWidthDetail");
            return;
          }
          this.$notify.error({ message: res.data.msg });
        });
    },
    //获取用户信息
    GetUserInfo() {
      //从本地缓存拿取用户id
      let postId = localStorage.getItem("postId");
      //判断是否有值
      if (postId == null || postId == undefined || postId == "") {
        this.handleClose();
        return;
      }
      //删除缓存值
      localStorage.removeItem("postId");
      //获取用户信息
      this.$axios
        .get(`/post/adminGetPostDetail?postId=${postId}`)
        .then((res) => {
          this.form = res.data.data;
          //修改帖子的状态值
          if (this.form.post.status == 1) {
            this.form.post.status = "待删除";
          } else {
            this.form.post.status = "未删除";
          }
          //截取图片数据
          this.images = this.form.detail.img.split(",");
        });
    },
    //路由回退
    handleClose() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .form {
    .el-form {
      .el-form-item {
      }
    }
  }
  .button {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
#dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.785);
}
</style>