<template>
  <div>
    <el-dialog
      title="修改订单信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="box" v-if="form != null">
        <div class="form">
          <el-form :inline="true" label-width="100px">
            <el-form-item label="订单ID">
              <el-input clearable="" disabled v-model="form.id"></el-input>
            </el-form-item>

            <el-form-item label="订单关联ID">
              <el-input
                clearable=""
                disabled
                v-model="form.serverId"
              ></el-input>
            </el-form-item>

            <el-form-item label="接单用户">
              <el-input
                clearable=""
                disabled
                v-model="form.serverOrderId"
              ></el-input>
            </el-form-item>

            <el-form-item label="订单类型：">
              <el-input
                clearable=""
                disabled
                v-model="form.serverType"
              ></el-input>
            </el-form-item>

            <el-form-item label="发布用户">
              <el-input
                clearable=""
                disabled
                v-model="form.serverPublishId"
              ></el-input>
            </el-form-item>
            <el-form-item label="订单状态">
              <el-select clearable="" v-model="form.serverStatus">
                <el-option value="未接单"></el-option>
                <el-option value="待支付"></el-option>
                <el-option value="进行中"></el-option>
                <el-option value="已取消"></el-option>
                <el-option value="已完成"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="订单小费">
              <el-input
                clearable=""
                disabled
                v-model="form.serverTip"
              ></el-input>
            </el-form-item>

            <el-form-item label="订单费用">
              <el-input
                clearable=""
                disabled
                v-model="form.serverMoney"
              ></el-input>
            </el-form-item>

            <el-form-item label="订单总额">
              <el-input
                clearable=""
                disabled
                v-model="form.serverAllMoney"
              ></el-input>
            </el-form-item>

            <el-form-item label="截至时间">
              <el-input
                clearable=""
                v-model="form.serverDeadlineTime"
              ></el-input>
            </el-form-item>

            <el-form-item label="创建时间">
              <el-input clearable="" v-model="form.serverCreateTime"></el-input>
            </el-form-item>

            <el-form-item label="支付时间">
              <el-input clearable="" v-model="form.serverPayTime"></el-input>
            </el-form-item>

            <el-form-item label="接单时间">
              <el-input clearable="" v-model="form.serverOrderTime"></el-input>
            </el-form-item>

            <el-form-item label="确认时间">
              <el-input
                clearable=""
                v-model="form.serverConfirmTime"
              ></el-input>
            </el-form-item>

            <el-form-item label="微信订单">
              <el-input
                clearable=""
                v-model="form.transactionId"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="button">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" type="warning" @click="ToLookServerPublishId()"
            >查看发布用户</el-button
          >
          <el-button size="mini" type="success" @click="ToLookOrderUser()"
            >查看接单用户</el-button
          >
          <el-button size="mini" type="info" @click="ToLookServer()"
            >查看订单详情</el-button
          >
          <el-button size="mini" type="danger" @click="RefundMoney()"
            >退款</el-button
          >
          <el-button size="mini" type="primary" @click="AdminUpdateUserInfo()"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Encrypt } from "@/utils/secret.js";
export default {
  data() {
    return {
      dialogVisible: true,
      form: null, //表单信息
      serverType: "", //订单详情
    };
  },
  mounted() {
    //获取用户信息
    this.GetUserInfo();
  },
  methods: {
    //对数据进行退款操作
    RefundMoney() {
      //判断订单微信订单数据是否为空状态
      if (this.form.transactionId == null || this.form.transactionId == "") {
        this.$notify.warning({ message: "订单退款数据为空" });
        return;
      }
      //判断订单状态是否为空
      if (this.form.serverStatus == "已完成") {
        this.$notify.warning({ message: "请先修改订单状态" });
        return;
      }
      //获取私钥
      this.$prompt("请输入退款私钥：", "水院贴吧", { type: "warning" })
        .then((value) => {
          //获取私钥
          const password = value.value;
          //判断密钥长度是否小于
          if (password.length < 12) {
            //密钥错误
            this.$notify.error({ message: "密钥错误" });
            return;
          }
          //进行密钥加密
          let key = Encrypt(password);
          //发起请求
          this.$axios
            .get(`/server/adminRefundMoney?id=${this.form.id}&key=${key}`)
            .then((res) => {
              //判断是否成功
              if (res.data.code == 200) {
                this.$notify.success({ message: "退款成功" });
                return;
              }
              //错误
              this.$notify.error({
                message: "已记录该操作管理员，错误信息：" + res.data.msg,
              });
            });
        })
        .catch(() => {});
    },
    //查看接单用户详情
    ToLookOrderUser() {
      //接单用户为空
      if (this.form.serverOrderId == null || this.form.serverOrderId == "") {
        this.$notify.error({ message: "暂未有用户接单" });
        return;
      }
      localStorage.setItem("userId", this.form.serverOrderId);
      this.$router.push("/index/user/update");
      localStorage.setItem("serverId", this.form.id);
    },
    //查看订单详情
    ToLookServer() {
      localStorage.setItem("orderId", this.form.serverId);
      switch (this.form.serverType) {
        case "食堂代打饭":
          this.$router.push("/index/order/eat");
          localStorage.setItem("serverId", this.form.id);
          return;
        case "快递代取":
          this.$router.push("/index/order/express");
          localStorage.setItem("serverId", this.form.id);
          return;
        case "帮买服务":
          this.$router.push("/index/order/buy");
          localStorage.setItem("serverId", this.form.id);
          return;
        case "代替服务":
          this.$router.push("/index/order/replace");
          localStorage.setItem("serverId", this.form.id);
          return;
        case "游戏开黑":
          this.$router.push("/index/order/game");
          localStorage.setItem("serverId", this.form.id);
          return;
        case "其它服务":
          this.$router.push("/index/order/it");
          localStorage.setItem("serverId", this.form.id);
          return;
        default:
          this.$notify.error({ message: "服务异常" });
          localStorage.removeItem("orderId");
          return;
      }
    },
    //查看发布用户详情
    ToLookServerPublishId() {
      localStorage.setItem("userId", this.form.serverPublishId);
      this.$router.push("/index/user/update");
      localStorage.setItem("serverId", this.form.id);
    },
    //修改信息
    AdminUpdateUserInfo() {
      this.$axios.post(`/server/adminUpdateServer`, this.form).then((res) => {
        this.handleClose();
        //请求数据成功
        if (res.data.code == 200) {
          this.$notify.success({ message: "修改成功" });
          this.$bus.$emit("UpdateServerOrder");
          return;
        }
        this.$notify.error({ message: res.data.msg });
      });
    },
    //获取用户信息
    GetUserInfo() {
      //从本地缓存拿取用户id
      let serverId = localStorage.getItem("serverId");
      //判断是否有值
      if (serverId == null || serverId == undefined || serverId == "") {
        this.handleClose();
        return;
      }
      //删除缓存值
      localStorage.removeItem("serverId");
      //获取用户信息
      this.$axios
        .get(`/server/adminGetServerOne?id=${serverId}`)
        .then((res) => {
          this.form = res.data.data;
          this.serverType = this.form.serverType;
        });
    },
    //路由回退
    handleClose() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .form {
    .el-form {
      .el-form-item {
      }
    }
  }
  .button {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
#dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.785);
}
</style>