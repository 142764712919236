<template>
  <div>
    <el-dialog
      title="修改用户信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div @click.stop class="box">
        <div class="form">
          <el-form
            :inline="true"
            :model="form"
            :rules="rules"
            label-width="100px"
          >
            <el-form-item prop="id" label="ID">
              <el-input clearable="" disabled v-model="form.id"></el-input>
            </el-form-item>

            <el-form-item prop="nickname" label="昵称">
              <el-input clearable="" v-model="form.nickname"></el-input>
            </el-form-item>

            <el-form-item prop="studentNumber" label="学号">
              <el-input clearable="" v-model="form.studentNumber"></el-input>
            </el-form-item>

            <el-form-item prop="username" label="姓名">
              <el-input clearable="" v-model="form.username"></el-input>
            </el-form-item>

            <el-form-item prop="sex" label="性别">
              <el-select v-model="form.sex">
                <el-option value="男"></el-option>
                <el-option value="女"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="phone" label="手机号">
              <el-input clearable="" v-model="form.phone"></el-input>
            </el-form-item>

            <el-form-item prop="signTime" label="注册时间">
              <el-input clearable="" v-model="form.signTime"></el-input>
            </el-form-item>

            <el-form-item prop="ip" label="IP地址">
              <el-input clearable="" disabled v-model="form.ip"></el-input>
            </el-form-item>

            <el-form-item prop="wechatId" label="微信唯一值">
              <el-input
                clearable=""
                disabled
                v-model="form.wechatId"
              ></el-input>
            </el-form-item>

            <el-form-item prop="money" label="余额">
              <el-input clearable="" disabled v-model="form.money"></el-input>
            </el-form-item>

            <el-form-item prop="userStatus" label="用户状态">
              <el-select v-model="form.userStatus">
                <el-option value="正常"></el-option>
                <el-option value="封禁"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="userStatus" label="头像地址">
              <el-input v-model="form.avatarUrl" clearable=""></el-input>
            </el-form-item>
            <el-form-item
              prop="avatarUrl"
              v-if="
                form.avatarUrl != null &&
                form.avatarUrl != undefined &&
                form.avatarUrl != ''
              "
              label="头像"
            >
              <el-image
                style="width: 200px; height: 200px"
                :lazy="false"
                :src="webURL + form.avatarUrl"
                :preview-src-list="[webURL + form.avatarUrl]"
              >
              </el-image>
            </el-form-item>
          </el-form>
        </div>
        <div class="button">
          <el-button size="mini" @click="handleClose">取消</el-button>
          <el-button size="mini" type="primary" @click="AdminUpdateUserInfo()"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      form: {}, //表单信息
      rules: {
        id: [{ required: true, message: "必填" }],
        nickname: [{ required: true, message: "必填" }],
        signTime: [{ required: true, message: "必填" }],
        ip: [{ required: true, message: "必填" }],
        wechatId: [{ required: true, message: "必填" }],
        money: [{ required: true, message: "必填" }],
        userStatus: [{ required: true, message: "必填" }],
      }, //规则
      webURL: this.$axios.defaults.webURL, //网站端口路径
    };
  },
  mounted() {
    //获取用户信息
    this.GetUserInfo();
  },
  methods: {
    //修改用户信息
    AdminUpdateUserInfo() {
      //判断必填项是否为空
      if (
        this.form.signTime == "" ||
        this.form.userStatus == "" ||
        this.form.nickname == ""
      ) {
        this.$notify.error({ message: "修改错误" });
        return;
      }
      //修改性别为0或1
      if (this.form.sex == "男") {
        this.form.sex = 0;
      } else if (this.form.sex == "女") {
        this.form.sex = 1;
      } else {
        this.$notify.error({ message: "性别错误" });
        return;
      }
      //修改用户状态
      //修改性别为0或1
      if (this.form.userStatus == "正常") {
        this.form.userStatus = 0;
      } else if (this.form.userStatus == "封禁") {
        this.form.userStatus = 1;
      } else {
        this.$notify.error({ message: "用户状态错误" });
        return;
      }
      this.$axios.post("/user/adminUpdateUserInfo", this.form).then((res) => {
        this.handleClose();
        //请求数据成功
        if (res.data.code == 200) {
          this.$notify.success({ message: "修改成功" });
          this.$bus.$emit("AdminUpdateUserInfo");
          return;
        }
        this.$notify.error({ message: res.data.msg });
      });
    },
    //获取用户信息
    GetUserInfo() {
      //从本地缓存拿取用户id
      let userId = localStorage.getItem("userId");
      //判断是否有值
      if (userId == null || userId == undefined || userId == "") {
        this.handleClose();
        return;
      }
      //删除缓存值
      localStorage.removeItem("userId");
      //获取用户信息
      this.$axios.get(`/user/adminGetUserInfo?userId=${userId}`).then((res) => {
        this.form = res.data.data;
        //修改用户的性别
        if (this.form.sex == 0) {
          this.form.sex = "男";
        } else {
          this.form.sex = "女";
        }
        //修改用户的状态
        if (this.form.userStatus == 1) {
          this.form.userStatus = "封禁";
        } else {
          this.form.userStatus = "正常";
        }
      });
    },
    //路由回退
    handleClose() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .form {
    .el-form {
      .el-form-item {
      }
    }
  }
  .button {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
#dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.785);
}
</style>