<template>
  <div class="box">
    <div class="left" :class="{ isClose: isClose, isShow: isShow }">
      <el-menu
        :default-active="activePath"
        class="el-menu-vertical-demo"
        background-color="#304156"
        text-color="#fff"
        active-text-color="#1179ff"
        style="height: 100vh"
        router
        :collapse="isCollapse"
        :collapse-transition="false"
        @select="handleSelect"
        :unique-opened="true"
      >
        <el-submenu index="/home-group">
          <template slot="title">
            <i class="el-icon-s-home"></i>
            <span>主页</span>
          </template>
          <el-menu-item index="/index/home"
            ><i class="el-icon-news"></i>仪表盘</el-menu-item
          >
        </el-submenu>

        <el-submenu index="/user-group">
          <template slot="title">
            <i class="el-icon-user"></i>
            <span>用户列表</span>
          </template>
          <el-menu-item index="/index/user"
            ><i class="el-icon-user-solid"></i>用户</el-menu-item
          >
          <el-menu-item index="/index/address"
            ><i class="el-icon-truck"></i>用户地址</el-menu-item
          >
          <el-menu-item index="/index/manage"
            ><i class="el-icon-s-tools"></i>管理员</el-menu-item
          >
        </el-submenu>

        <el-submenu index="/post">
          <template slot="title">
            <i class="el-icon-s-help"></i>
            <span>帖子列表</span>
          </template>
          <el-menu-item index="/index/post"
            ><i class="el-icon-document-copy"></i>用户帖子</el-menu-item
          >
          <el-menu-item index="/index/comment"
            ><i class="el-icon-chat-dot-round"></i>一级评论</el-menu-item
          >
          <el-menu-item index="/index/review"
            ><i class="el-icon-chat-line-round"></i>二级评论</el-menu-item
          >
          <el-menu-item index="/index/collect"
            ><i class="el-icon-star-off"></i>用户收藏</el-menu-item
          >
          <el-menu-item index="/index/loves"
            ><i class="el-icon-medal"></i>用户点赞</el-menu-item
          >
          <el-menu-item index="/index/message"
            ><i class="el-icon-bell"></i>用户通知</el-menu-item
          >
          <el-menu-item index="/index/report"
            ><i class="el-icon-warning-outline"></i>举报列表</el-menu-item
          >
        </el-submenu>

        <el-submenu index="/server">
          <template slot="title">
            <i class="el-icon-receiving"></i>
            <span>服务列表</span>
          </template>
          <el-menu-item index="/index/order"
            ><i class="el-icon-sell"></i>服务订单</el-menu-item
          >
          <el-menu-item index="/index/paylog"
            ><i class="el-icon-mobile"></i>支付日志</el-menu-item
          >
        </el-submenu>

        <el-submenu index="/clerk">
          <template slot="title">
            <i class="el-icon-s-promotion"></i>
            <span>接单员列表</span>
          </template>
          <el-menu-item index="/index/clerk"
            ><i class="el-icon-position"></i>接单员数据</el-menu-item
          >
          <el-menu-item disabled index="/index/withdraw-deposit"
            ><i class="el-icon-takeaway-box"></i>提现日志</el-menu-item
          >
        </el-submenu>

        <el-submenu index="/production">
          <template slot="title">
            <i class="el-icon-cold-drink"></i>
            <span>作品列表</span>
          </template>
          <el-menu-item index="/index/production"
            ><i class="el-icon-goblet-square-full"></i>用户作品</el-menu-item
          >
        </el-submenu>

        <el-submenu index="/system">
          <template slot="title">
            <i class="el-icon-setting"></i>
            <span>系统操作</span>
          </template>
          <el-menu-item index="/index/system"
            ><i class="el-icon-monitor"></i>操作日志</el-menu-item
          >
        </el-submenu>
      </el-menu>
    </div>
    <div class="right" :class="{ isClose: isClose }">
      <div class="index-top">
        <IndexTop></IndexTop>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import IndexTop from "@/components/index/common/IndexTop.vue";
export default {
  components: { IndexTop },
  data() {
    return {
      activePath: this.$route.path, //当前路径
      isCollapse: false, //是否展示
      isShow: false, //展示动画
      isClose: false, //关闭动画
    };
  },
  mounted() {
    //初始化数据
    this.init();
  },
  methods: {
    //选择回调
    handleSelect() {
      this.$bus.$emit("UpdateBreadcrumb");
    },
    //刷新浏览器
    Reload() {
      location.reload();
    },
    //初始化数据
    init() {
      //判断是否登录
      let user = localStorage.getItem("user");
      if (user == null || user == undefined || user == "") {
        this.$router.replace("/login");
        return;
      }
      //导航栏
      this.$bus.$on("ShowMenuLeft", () => {
        this.isCollapse = !this.isCollapse;
        this.isClose = this.isCollapse;
        this.isShow = !this.isCollapse;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.left {
  width: 180px;
  overflow: auto;
  .el-menu {
    border-right: none;
  }
  .el-submenu .el-menu-item {
    min-width: 0px;
  }
  &.isClose {
    animation: MoveCloseLeft 3s forwards;
    .el-menu {
      animation: MoveClose 1s forwards;
    }
  }
  &.isShow {
    animation: MoveShowLeft 3s forwards;
    .el-menu {
      animation: MoveShow 1s forwards;
    }
  }
  @keyframes MoveCloseLeft {
    100% {
      width: 64px;
    }
  }
  @keyframes MoveClose {
    from {
      width: 180px;
    }
    to {
      width: 64px;
    }
  }
  @keyframes MoveShowLeft {
    100% {
      width: 180px;
    }
  }
  @keyframes MoveShow {
    0% {
      width: 64px;
    }
    100% {
      width: 180px;
    }
  }
}

.right {
  .index-top {
    position: sticky;
    top: 0px;
    z-index: 99999;
    background-color: white;
  }
  width: calc(100vw - 180px);
  overflow: auto;
  &.isClose {
    animation: MoveRightClose 1s ease-in forwards;
  }
  @keyframes MoveRightClose {
    100% {
      width: calc(100vw - 64px);
    }
  }
}
</style>