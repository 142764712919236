<template>
  <div>
    <div class="header">
      <div class="top">
        <div class="left">
          <i class="el-icon-search"></i>
          <span> 筛选搜索</span>
        </div>
        <div class="right">
          <el-button size="mini" icon="el-icon-refresh" @click="Refresh()"
            >重置搜索</el-button
          >
          <el-button
            size="mini"
            icon="el-icon-search"
            type="primary"
            @click="Search()"
            >查询结果</el-button
          >
        </div>
      </div>
      <div class="bottom">
        <el-form inline>
          <el-row>
            <el-col :span="8">
              <el-form-item label="订单ID：">
                <el-input clearable v-model="form.id"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单关联ID：">
                <el-input clearable v-model="form.serverId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="发布用户ID：">
                <el-input clearable v-model="form.serverPublishId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="接单用户ID：">
                <el-input clearable v-model="form.serverOrderId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单状态：">
                <el-select clearable="" v-model="form.serverStatus">
                  <el-option value="未接单"></el-option>
                  <el-option value="待支付"></el-option>
                  <el-option value="进行中"></el-option>
                  <el-option value="已取消"></el-option>
                  <el-option value="已完成"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="订单类型：">
                <el-select clearable="" v-model="form.serverType">
                  <el-option value="食堂代打饭"></el-option>
                  <el-option value="快递代取"></el-option>
                  <el-option value="帮买服务"></el-option>
                  <el-option value="代替服务"></el-option>
                  <el-option value="游戏开黑"></el-option>
                  <el-option value="其它服务"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="截至时间：">
                <el-date-picker
                  clearable
                  v-model="deadlineTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始范围"
                  end-placeholder="结束范围"
                  align="right"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间：">
                <el-date-picker
                  clearable
                  v-model="createTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始范围"
                  end-placeholder="结束范围"
                  align="right"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支付时间：">
                <el-date-picker
                  clearable
                  v-model="payTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始范围"
                  end-placeholder="结束范围"
                  align="right"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="接单时间：">
                <el-date-picker
                  clearable
                  v-model="orderTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始范围"
                  end-placeholder="结束范围"
                  align="right"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="确认时间：">
                <el-date-picker
                  clearable
                  v-model="sureTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始范围"
                  end-placeholder="结束范围"
                  align="right"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微信订单：">
                <el-input v-model="form.transactionId" clearable=""></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="list">
      <div class="top">
        <div class="left">
          <i class="el-icon-tickets"></i>
          <span> 数据列表</span>
        </div>
        <div class="right"></div>
      </div>
    </div>
    <div class="center">
      <el-table
        :data="servers"
        border=""
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="排序">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="订单状态" prop="serverStatus"></el-table-column>
        <el-table-column label="订单类型" prop="serverType"></el-table-column>
        <el-table-column
          label="订单金额"
          prop="serverAllMoney"
        ></el-table-column>
        <el-table-column label="操作" prop="">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="UpdateUserInfo(servers[scope.$index].id)"
              >编辑</el-button
            >
            <el-button size="mini" @click="DeletePost(servers[scope.$index].id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <div class="batch">
        <div class="left">
          <el-select placeholder="批量操作" clearable="" v-model="batch">
          </el-select>
        </div>
        <div class="right">
          <el-button type="primary">确定</el-button>
        </div>
      </div>
      <div class="page">
        <el-pagination
          @current-change="onCurrentChange"
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        id: "", //订单id
        serverId: "", //订单关联id
        serverPublishId: "", //订单发布用户
        serverOrderId: "", //订单接单用户
        serverStatus: "", //订单当前状态
        serverType: "", //订单类型
        skip: 1, //翻页数据
        startDeadlineTime: "", //订单结束时间
        endDeadlineTime: "", //订单结束时间
        startCreateTime: "", //订单创建时间
        endCreateTime: "", //订单创建时间
        startPayTime: "", //订单支付时间
        endPayTime: "", //订单支付时间
        startOrderTime: "", //订单接单时间
        endOrderTime: "", //订单接单时间
        startSureTime: "", //订单确认时间
        endSureTime: "", //订单确认时间
        transactionId: "", //订单id
      }, //搜索表单数据
      servers: [], //帖子数据列表
      total: 0, //条数信息
      deadlineTime: null, //截至时间
      createTime: null, //创建时间
      payTime: null, //支付时间
      orderTime: null, //接单时间
      sureTime: null, //确定时间
      batch: "", //用户选择操作
    };
  },
  mounted() {
    //查询数据
    this.GetAllUserInfo();
    //初始化数据
    this.init();
  },
  beforeDestroy() {},
  methods: {
    //初始化数据
    init() {
      //重新请求数据
      this.$bus.$on("UpdateServerOrder", () => {
        this.GetAllUserInfo();
      });
      //挂载删除信息
      this.$bus.$on("DeleteServer", (id) => {
        //发起请求
        this.$axios
          .get(`/server/adminDeleteServerOrderOne?id=${id}`)
          .then((res) => {
            // 删除成功
            if (res.data.code == 200) {
              this.$notify.success({ message: "删除成功" });
              setTimeout(() => {
                this.GetAllUserInfo();
              }, 10);
            }
            this.$notify.error({ message: res.data.msg });
          });
      });
    },
    //展示弹窗信息
    UpdateUserInfo(id) {
      localStorage.setItem("serverId", id);
      this.$router.push("/index/order/update");
    },
    //删除操作
    DeletePost(id) {
      //询问是否确定删除
      this.$confirm("该操作不可逆，将清空所有有关该服务的数据！", "水院论坛", {
        type: "warning",
      })
        .then(() => {
          localStorage.setItem("verify", "delete-server-admin");
          localStorage.setItem("userId", id);
          //验证身份
          this.$router.push("/verify");
        })
        .catch(() => {
          this.$notify.warning({ message: "危险操作已取消" });
        });
    },
    //翻页操作
    onCurrentChange(e) {
      this.form.skip = e;
      this.GetAllUserInfo();
    },
    //重置搜索
    Refresh() {
      //刷新form表单
      let key = Object.keys(this.form);
      key.forEach((item) => {
        this.form[item] = "";
      });
      this.form.skip = 1;
      //请求数据
      this.GetAllUserInfo();
    },
    //选择数据
    handleSelectionChange() {},
    //获取信息
    Search() {
      //查询时间数据
      if (this.deadlineTime != null) {
        this.form.startDeadlineTime = this.deadlineTime[0];
        this.form.endDeadlineTime = this.deadlineTime[1];
      }
      if (this.createTime != null) {
        this.form.startCreateTime = this.createTime[0];
        this.form.endCreateTime = this.createTime[1];
      }
      if (this.payTime != null) {
        this.form.startPayTime = this.payTime[0];
        this.form.endPayTime = this.payTime[1];
      }
      if (this.orderTime != null) {
        this.form.startOrderTime = this.orderTime[0];
        this.form.endOrderTime = this.orderTime[1];
      }
      if (this.sureTime != null) {
        this.form.startSureTime = this.sureTime[0];
        this.form.endSureTime = this.sureTime[1];
      }
      this.GetAllUserInfo();
    },
    //获取用户信息
    GetAllUserInfo() {
      this.$axios.post("/server/adminGetServerAll", this.form).then((res) => {
        this.total = res.data.code;
        this.servers = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 90%;
  border: 1px solid #e3dfdf86;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .top {
    display: flex;
    justify-content: space-between;
  }
  .bottom {
    margin-top: 30px;
  }
}

.list {
  width: 90%;
  border: 1px solid #e3dfdf86;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .top {
    display: flex;
    justify-content: space-between;
  }
}
.center {
  width: 90%;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
  .el-table {
    margin: auto;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  .batch {
    display: flex;
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>